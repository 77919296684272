<template>
  <div>
    <div class="video-menu">
      <div class="buttons">
        <div
          class="upload-video-area"
          :style="uploadBlockBackground"
          :multiple="true"
          @dragover.prevent="changeBackground = true"
          @dragleave.prevent="changeBackground = false"
          @drop.prevent="onFileChanged"
          @click="$refs.fileInput.click()"
        >
          <section class="flex-centered">
            <button class="upload-button">
              {{ $t("create.step2.uploadZone.button") }}
            </button>
            <p class="upload-drop-text-floating">
              {{ $t("create.step2.uploadZone.orDrop2") }}
            </p>
          </section>
          <input
            id="fileUploaded"
            ref="fileInput"
            class="hidden"
            multiple
            type="file"
            accept="video/*"
            @change="onFileChanged"
          />
        </div>
        <button class="quick-import-button" @click="openVideoLibrary">
          <VidjetLogo class="icon-logo" />
          <p>{{ $t("create.step2.videoLibrary") }}</p>
        </button>
        <google-picker
          class="quick-import-button"
          @fileInput="onFileChanged"
        ></google-picker>
        <!-- <button
          class="quick-import-button"
          @click="toggleImporterModal('youtube')"
        >
          <img
            :src="require('../assets/youtube-logo.svg')"
            class="youtube-logo"
            alt="logo youtube"
          />
          <span>Youtube</span>
        </button> -->
      </div>
    </div>
    <small-modal
      v-if="selectedImporter === 'youtube'"
      :fit-content="true"
      @close="removeVideos"
    >
      <YoutubeImporter
        :is-loading="isLoading"
        @import-video="onFileChanged($event)"
        @remove-videos="removeVideos"
        @close="createVideosFromYoutube"
      />
    </small-modal>
    <PlanRegistration
      v-if="showPlanRegistrationModal"
      :new-plan="proposedPlan"
      :show-arrows="currentPlan !== 'scale'"
      @close="showPlanRegistrationModal = false"
    />
    <spinner-loader
      v-if="isLoading"
      class="spinner-loader"
      :loading-text="$t('shared.loader.uploadingVideo')"
      :loading-text-additional="$t('shared.loader.uploadingVideo2')"
      :start-value="getUploadVideos.videosUploaded"
      :end-value="numberOfVideosToUpload"
    ></spinner-loader>
  </div>
</template>

<script>
import GooglePicker from "@/components/shared/GooglePicker.vue";
import VidjetLogo from "@/assets/svg/vidjet-logo-library.svg?inline";
import PlanRegistration from "@/components/upgrade-plan/PlanRegistration.vue";
import SmallModal from "@/components/shared/SmallModal";
import YoutubeImporter from "@/components/create-campaign-steps/YoutubeImporter.vue";
import SpinnerLoader from "@/components/shared/SpinnerLoader.vue";
import { mapGetters, mapState } from "vuex";
import { generateVideoCover } from "@/utils/generate-video-cover";
import api from "@/api";

export default {
  components: {
    GooglePicker,
    VidjetLogo,
    PlanRegistration,
    SmallModal,
    YoutubeImporter,
    SpinnerLoader,
  },
  data() {
    return {
      changeBackground: false,
      showPlanRegistrationModal: false,
      files: [],
      selectedImporter: "",
      proposedPlan: {},
      isLoading: false,
    };
  },
  computed: {
    ...mapState(["site"]),
    ...mapGetters({
      videosInCampaignsLimit: "plan/videosInCampaignsLimit",
      numberOfVideosInLibrary: "video/numberOfVideosInLibrary",
      currentPlan: "plan/currentPlan",
      getVideoLibrary: "video/getVideoLibrary",
      siteId: "account/getSiteId",
      getUploadVideos: "video/getUploadVideos",
    }),
    numberOfVideosToUpload() {
      return this.files.filter((file) => !file._id).length;
    },
    uploadBlockBackground() {
      const opacity = this.changeBackground ? "0.1" : "0.3";
      return `background: rgba(226, 124, 252, ${opacity})`;
    },
  },
  methods: {
    openVideoLibrary() {
      this.$emit("open-video-library");
    },
    async onFileChanged(payload) {
      let videosToUpload;
      if (payload.type === "drop") {
        videosToUpload = Array.from(payload.dataTransfer.files);
      } else if (payload.type === "quick-import") {
        videosToUpload = [payload.file];
      } else {
        videosToUpload = Array.from(payload.target.files);
      }

      if (
        videosToUpload?.length &&
        this.numberOfVideosInLibrary + videosToUpload.length >
          this.videosInCampaignsLimit
      ) {
        return this.showPlanRegistration();
      }

      this.isLoading = true;

      // Process each video file
      for (const videoToUpload of videosToUpload) {
        const loadedmetadataEvent = await this.processToVideoCheck(
          videoToUpload
        );
        if (!loadedmetadataEvent) continue;

        // Generate video object
        const videoMetadata = this.addMetadata(loadedmetadataEvent);
        let thumbnail = await generateVideoCover(
          URL.createObjectURL(videoToUpload),
          4
        );
        thumbnail = URL.createObjectURL(thumbnail);

        let fileName = videoToUpload.name;
        let count = 1;
        while (this.fileNameExists(fileName)) {
          fileName = this.getUniqueFileName(videoToUpload.name, count);
          count++;
        }

        const videoObject = {
          name: fileName,
          videoToUpload: videoToUpload,
          url: URL.createObjectURL(videoToUpload),
          thumbnail,
        };

        this.files.push({
          ...videoMetadata,
          ...videoObject,
        });

      }

      // After pushing all video objects to files array, create videos in the store
      if (payload.source !== "youtube") {
        const createdVideos = [];
        for (let i = 0; i < this.files.length; i++) {
          const createdVideo = await this.createVideo(
            this.setVideoObject(this.files[i]),
            i
          );
          createdVideos.push(createdVideo);
        }

        // Emit the created videos
        this.$emit("push-files", createdVideos);
      }

      this.isLoading = false;
      this.files = [];
    },
    async processToVideoCheck(video) {
      if (!this.checkFileType(video.name)) {
        return;
      }
      this.checkFileSize(video.size);
      if (!this.isFileTooBig) {
        this.isFileTooBig = false;
        const res = await this.isBrowserCompatible(video);
        !res && this.togglePlayErrorModal();
        return res;
      }
    },
    checkFileType(fileName) {
      if (!this.validateFileType(fileName)) {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("create.step2.alerts.supportedFormats"),
          type: "error",
        });
        return false;
      }
      return true;
    },
    validateFileType(fileName) {
      const videoFormatsRegex = /(.mov|.mp4|.webm|.ogg)$/i;

      return videoFormatsRegex.test(fileName);
    },
    checkFileSize(size) {
      const sizeInMB = size / (1024 * 1024);
      if (
        this.currentPlan === "vidjet_tier3" &&
        sizeInMB > this.appsumoMaxFileSize
      ) {
        this.isFileTooBig = true;
        this.resetVideo();
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.appsumoYourFileExceeds"),
          type: "error",
        });
        if (sizeInMB > 300) {
          this.isFileTooBig = true;
          this.resetVideo();
          this.$notify({
            title: this.$t("shared.toastMessage.error"),
            text: this.$t("shared.toastMessage.yourFileExceeds"),
            type: "error",
          });
          return;
        }

        return;
      }
    },
    addMetadata(event) {
      const { videoHeight, videoWidth, duration } = event;
      this.videoMetadataLoading = false;
      return {
        height: videoHeight,
        width: videoWidth,
        videoDuration: duration,
      };
    },
    async isBrowserCompatible(file) {
      let video = document.createElement("video");
      video.setAttribute("src", window.URL.createObjectURL(file));
      var playPromise = video.play();
      video.muted = true;
      this.videoMetadataLoading = true;
      // wait for metadata to load to return a value
      const retVal = await new Promise((resolve) => {
        video.addEventListener("loadedmetadata", async (event) => {
          if (event.target.videoHeight === 0) {
            await playPromise.catch(() => {
              this.sendCodecsLog(file);
            });
            resolve(false);
          } else {
            video.pause();
            const target =
              event.target || event.currentTarget || event.originalTarget;
            resolve(target);
          }
        });
      });
      return retVal;
    },
    async sendCodecsLog(file) {
      let result = await this.retrieveCodecs(file);
      api.sendLog({
        logMsg: `Error uploading video for site ${this.siteId} with file ${file.name} and codecs: ${result}`,
      });
    },
    async retrieveCodecs(file) {
      let result = [];
      const MP4Box = require("mp4box");
      await new Promise((resolve, reject) => {
        const mp4boxFile = MP4Box.createFile();
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function (e) {
          const arrayBuffer = e.target.result;
          arrayBuffer.fileStart = 0;
          mp4boxFile.appendBuffer(arrayBuffer);
        };
        mp4boxFile.onReady = function (info) {
          info.tracks.forEach((track) => {
            result.push(track.codec);
          });
          resolve(mp4boxFile);
        };
        mp4boxFile.onError = function (info) {
          reject(info);
        };
      });
      return result;
    },
    fileNameExists(fileName) {
      return this.getVideoLibrary.some((video) => video.fileName === fileName);
    },
    toggleImporterModal(importer) {
      if (
        this.files.length &&
        this.numberOfVideosInLibrary + this.files.length >
          this.videosInCampaignsLimit
      ) {
        return this.showPlanRegistration();
      }
      this.showImporterModal = !this.showImporterModal;
      this.showImporterModal && (this.importUrl = "");
      this.selectedImporter = importer;
    },
    setVideoObject(file) {
      const videoData = {
        useCase: this.$route.query.goal,
        videoDuration: Number(file.videoDuration.toFixed(1)),
        height: file.height,
        width: file.width,
        isPremade: file.isPremade,
        _id: file._id,
      };

      videoData.tempFile = file.videoToUpload;
      videoData.fileName = file.name;
      videoData.url = file.url;
      videoData.thumbnail = file.thumbnail;

      return videoData;
    },
    async showPlanRegistration() {
      if (this.currentPlan === "pro") {
        window.Intercom &&
          window.Intercom("trackEvent", "plan_enterprise_clicked");
        this.notifyTooManyVideos();
        return;
      }
      this.proposedPlan = await this.$store.dispatch("plan/getProposedPlan", {
        siteId: this.siteId,
      });
      this.showPlanRegistrationModal = true;
      this.notifyTooManyVideos();
    },
    async createVideo(file, index) {
      const video = await this.$store.dispatch("video/createVideo", {
        file: {
          siteId: this.siteId,
          ...file,
        },
        index,
      });

      return video;
    },
    getUniqueFileName(fileName, count) {
      const extensionIndex = fileName.lastIndexOf(".");
      if (extensionIndex !== -1) {
        return `${fileName.slice(0, extensionIndex)} (${count})${fileName.slice(
          extensionIndex
        )}`;
      } else {
        return `${fileName} (${count})`;
      }
    },
    removeVideos() {
      this.files = [];
      this.toggleImporterModal();
    },
    async createVideosFromYoutube() {
      this.isLoading = true;
      const createdVideos = [];
      for (let i = 0; i < this.files.length; i++) {
        const createdVideo = await this.createVideo(
          this.setVideoObject(this.files[i]),
          i
        );
        createdVideos.push(createdVideo);
      }
      this.$emit("push-files", createdVideos);
      this.toggleImporterModal();
      this.isLoading = false;
      this.files = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.video-menu {
  z-index: 100;
  height: 70px;
  max-width: 850px;
  position: fixed;
  left: 50%;
  transform: translateX(-25%);
  bottom: 16px;
  background: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  border: 1px solid $light-grey;
  box-shadow: 0px 2px 8px 0px #00000026;
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}
.upload-video-area {
  cursor: pointer;
  border-radius: $card-border-radius;
  border: 2px dashed $dark-purple;
  height: 100%;
  padding: 12px;
  @include flex-centered;
  background: $light-pink;
  white-space: nowrap;
}
.flex-centered {
  @include flex-centered;
}
.upload-button {
  font-family: "Montserrat", sans-serif;
  background: $dark-purple;
  color: white;
  @include font-small;
  padding: 2px 12px;
  border-radius: 5px;
  margin-right: 6px;
}
.upload-drop-text-floating {
  font-size: 14px;
}

.hidden {
  display: none;
}
.quick-import-button {
  @include base-font;
  @include font-small;
  border-radius: 4px;
  background-color: white;
  padding: 0 5px;
  height: 30px;
  display: flex;
  align-content: center;
  align-items: center;
  gap: 8px;
  margin: 0px auto;
  box-sizing: content-box;
  white-space: nowrap;
  &:hover {
    background: $light-pink;
  }
  img {
    vertical-align: middle;
  }
  .youtube-logo {
    height: 30px;
  }
}
.spinner-loader {
  z-index: 100;
}
</style>
