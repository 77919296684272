<template>
  <small-modal
    :title="title"
    class="embed-code-modal-wrapper"
    :fit-content="true"
    @close="close"
  >
    <div class="embed-code-modal-content">
      <h2 class="subtitle">
        <span>{{ firstPart }}</span>
        <a :href="linkHref" class="anchor" target="_blank">{{ secondPart }}</a>
        <span v-if="thirdPart">{{ thirdPart }}</span>
      </h2>
      <div v-if="isCodeFormat && campaign" class="script-to-copy">
        <p class="iframe-code-to-copy">
          {{ getEmbedCode(campaign) }}
        </p>
        <button
          v-clipboard="
            () => getEmbedCode(campaign).replace(/(\r\n|\n|\r)/gm, '')
          "
          v-clipboard:success="clipboardSuccessHandler"
          v-clipboard:error="clipboardErrorHandler"
          class="action-button"
        >
          {{ $t("create.step5.copyCode") }}
        </button>
      </div>
      <banner-modal
        :title="$t('deleteCampaign.information')"
        :text="bannerText"
      >
        <slot class="banner-image">
          <img :src="bannerImg" alt="banner-img" />
        </slot>
      </banner-modal>
    </div>
  </small-modal>
</template>

<script>
import SmallModal from "../shared/SmallModal";
import getEmbedCode from "@/utils/get-embed-code.js";
import BannerModal from "../shared/BannerModal.vue";

export default {
  components: {
    SmallModal,
    BannerModal,
  },
  props: {
    campaign: { type: Object, default: null },
    isBulk: {
      type: Boolean,
      default: false,
    },
    isCodeFormat: {
      type: Boolean,
      default: false,
    },
    isHomePage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: "Your widget has been created! 🎉",
      getEmbedCode,
    };
  },
  computed: {
    firstPart() {
      if (this.isCodeFormat && this.isBulk) {
        return this.$t("widgetCreator.widgetCreatedModal.subtitleCodeBulk1");
      } else if (this.isCodeFormat && this.isHomePage) {
        return this.$t("widgetCreator.widgetCreatedModal.subtitleCodeHome1");
      } else if (this.isCodeFormat) {
        return this.$t("widgetCreator.widgetCreatedModal.subtitleCode1");
      } else if (!this.isCodeFormat && this.isBulk) {
        return this.$t("widgetCreator.widgetCreatedModal.subtitleNoCodeBulk");
      } else if (!this.isCodeFormat && this.isHomePage) {
        return this.$t("widgetCreator.widgetCreatedModal.subtitleNoCodeHome");
      }
      return this.$t("widgetCreator.widgetCreatedModal.subtitleNoCode");
    },
    secondPart() {
      if (this.isCodeFormat && this.isBulk) {
        return this.$t("widgetCreator.widgetCreatedModal.subtitleCodeBulk2");
      } else if (this.isCodeFormat && this.isHomePage) {
        return this.$t("widgetCreator.widgetCreatedModal.subtitleCodeHome2");
      } else if (this.isCodeFormat) {
        return this.$t("widgetCreator.widgetCreatedModal.subtitleCode2");
      }
      return "";
    },
    thirdPart() {
      if (this.isCodeFormat && this.isBulk) {
        return this.$t("widgetCreator.widgetCreatedModal.subtitleCodeBulk3");
      }
      return "";
    },
    linkHref() {
      if (this.isCodeFormat && this.isBulk) {
        return "https://help.vidjet.io/en/articles/8368680-embedding-the-master-component-on-your-page-template";
      } else if (this.isCodeFormat && this.isHomePage) {
        return "https://help.vidjet.io/en/articles/8779193-embed-a-carousel-on-the-homepage";
      } else if (this.isCodeFormat) {
        return "https://help.vidjet.io/en/articles/8790660-embed-videos-on-landing-pages";
      }
      return "#";
    },
    bannerText() {
      if (this.isCodeFormat) {
        return this.$t("widgetCreator.widgetCreatedModal.bannerTextCodeBulk");
      } else if (!this.isCodeFormat && this.isBulk) {
        return this.$t("widgetCreator.widgetCreatedModal.bannerTextNoCodeBulk");
      }
      return this.$t("widgetCreator.widgetCreatedModal.bannerTextNoCode");
    },
    bannerImg() {
      if (this.isCodeFormat && this.isBulk) {
        return require("@/assets/create-widget-banner-images/code-bulk-img.png");
      } else if (this.isCodeFormat && !this.isBulk) {
        return require("@/assets/create-widget-banner-images/code-no-bulk-img.png");
      } else if (!this.isCodeFormat && this.isBulk) {
        return require("@/assets/create-widget-banner-images/no-code-bulk-img.png");
      } else {
        return require("@/assets/create-widget-banner-images/no-code-no-bulk-img.png");
      }
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    clipboardSuccessHandler() {
      this.$notify({
        title: this.$t("shared.toastMessage.copied"),
        type: "success",
      });
    },
    clipboardErrorHandler() {
      this.$notify({
        title: this.$t("shared.toastMessage.failedToCopy"),
        type: "error",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  @include base-font;
  @include font-normal;
  font-weight: 400;
  text-align: center;
  margin-bottom: 24px;
}
.embed-code-instruction-banner {
  width: 90%;
  margin-bottom: 16px;
}

.script-to-copy {
  position: relative;
  border: 1px solid $dark-purple;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: $card-border-radius;
  margin: 0 auto;
  overflow: auto;
  padding: 16px 24px;
  display: grid;
  grid-template-columns: 1fr auto;
  place-items: center;
  width: 100%;
  background-color: white;
  @include font-smaller;
  margin-bottom: 16px;
}
.iframe-code-to-copy {
  overflow: auto;
  width: 100%;
  color: $medium-grey;
}

.action-button {
  position: absolute;
  right: 16px;
  width: unset;
}
.iframe-code-to-copy::-webkit-scrollbar {
  width: 0;
}

.iframe-code-to-copy::-webkit-scrollbar-track {
  background-color: transparent;
}

.iframe-code-to-copy::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.banner-image {
  display: flex;
  justify-content: flex-start;
}
</style>
