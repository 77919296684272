<template>
  <section class="data-table">
    <div class="title-wrapper">
      <h2 v-if="!selectedCampaign" class="title">
        <MultiCampaignIcon /> {{ $t("dashboard.dashboardTable.campaignList") }}
      </h2>
      <h2 v-else class="title">
        <MultiVideoIcon /> {{ $t("dashboard.dashboardTable.videosInCampaign") }}
        <span class="bold">{{ selectedCampaign.name }}</span>
        <div class="link" @click="editCampaign(selectedCampaign)">
          <EditIcon />
          {{ $t("dashboard.dashboardTable.editCampaign") }}
        </div>
      </h2>
      <button
        v-if="selectedCampaign"
        class="back-icon"
        @click="selectedCampaign = null"
      >
        <ArrowBackIcon />
        <p>{{ $t("dashboard.dashboardTable.back") }}</p>
      </button>
    </div>
    <v-table v-if="!selectedCampaign" key="1" :data="data">
      <thead slot="head">
        <th class="big-column campaign-name">
          {{ $t("dashboard.dashboardTable.campaignName") }}
        </th>
        <th class="column">
          {{ $t("dashboard.dashboardTable.impressions") }}
        </th>
        <th class="column">
          {{ $t("dashboard.dashboardTable.videosPlayed") }}
        </th>
        <th class="column">{{ $t("dashboard.dashboardTable.salesCount") }}</th>
        <th class="column">{{ $t("dashboard.dashboardTable.salesAmount") }}</th>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr
          v-for="(row, index) in displayData"
          :key="row.id"
          class="row"
          @click="selectedCampaign = data[index]"
        >
          <td class="name-cell">
            <p
              v-tippy="{ arrow: true, placement: 'top', a11y: false }"
              class="name"
              :content="row.name"
            >
              {{ row.name }}
            </p>
            <div class="boxes">
              <p
                v-if="row.videos"
                v-tippy="{ arrow: true, placement: 'top', a11y: false }"
                :content="$t('campaignManager.videoNumber')"
              >
                <Slideshow></Slideshow>
                {{ row.videos.length }}
              </p>
              <p
                v-tippy="{ arrow: true, placement: 'top', a11y: false }"
                :content="$t('dashboard.formatTooltip')"
              >
                {{ displayFormatType(row.formats[0]) }}
              </p>
            </div>
          </td>
          <td>
            <p>{{ row.display }}</p>
          </td>
          <td>
            <p>{{ row.viewTimeCount }}</p>
          </td>
          <td>
            <p>{{ row.conversionCount }}</p>
          </td>
          <td class="action-column">
            <p class="revenue">
              {{ currencyDisplay(row.conversionAmount) }}
            </p>
            <p class="actions">
              {{ $t("dashboard.dashboardTable.details") }} <ArrowNext />
            </p>
          </td>
        </tr>
      </tbody>
    </v-table>
    <v-table v-else key="2" class="video-table" :data="selectedCampaign.videos">
      <thead slot="head">
        <th v-if="selectedCampaign.bulk" class="small-column">Feeds</th>
        <th v-else class="small-column">#</th>
        <th class="big-column video-title">
          {{ $t("dashboard.dashboardTable.videoFile") }}
        </th>
        <th class="column">
          {{ $t("dashboard.dashboardTable.impressions") }}
        </th>
        <th class="column">
          {{ $t("dashboard.dashboardTable.videosPlayed") }}
        </th>
        <th class="column">{{ $t("dashboard.dashboardTable.salesCount") }}</th>
        <th class="column">{{ $t("dashboard.dashboardTable.salesAmount") }}</th>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <template v-for="(row, index) in displayData">
          <tr :key="row.id">
            <td v-if="selectedCampaign.bulk" class="small-column">
              <p>{{ getFeedNumber(index) }}</p>
            </td>
            <td v-else class="small-column">
              <p>{{ index + 1 }}</p>
            </td>
            <td>
              <p
                v-tippy="{ arrow: true, placement: 'top', a11y: false }"
                class="video-name"
                :content="row.fileName"
              >
                <OpenIcon
                  class="open-icon"
                  @click="handleOpenVideoLibrarySlidingModal(row)"
                />
                {{ row.fileName }}
              </p>
            </td>
            <td>
              <p>{{ row.display }}</p>
            </td>
            <td>
              <p>{{ row.viewTimeCount }}</p>
            </td>
            <td>
              <p>{{ row.conversionCount }}</p>
            </td>
            <td>
              <p>{{ currencyDisplay(row.conversionAmount) }}</p>
            </td>
          </tr>
          <tr
            v-if="selectedCampaign.bulk && isGroupBoundary(index)"
            :key="'separator-' + index"
          >
            <td class="feed-separator-wrapper" colspan="7">
              <hr class="feed-separator" />
            </td>
          </tr>
        </template>
      </tbody>
    </v-table>
    <VideoLibrarySlidingModal
      v-if="modalData.openVideoLibrarySlidingModal"
      :preselected-query="modalData.fileName"
      @close-video-library="closeVideoLibrary"
    />
  </section>
</template>

<script>
import ArrowBackIcon from "@/assets/svg/arrow-back-6.svg?inline";
import Slideshow from "@/assets/svg/slideshow.svg?inline";
import MultiVideoIcon from "@/assets/svg/multiple-videos-icon.svg?inline";
import MultiCampaignIcon from "@/assets/svg/multiple-campaigns-icon.svg?inline";
import OpenIcon from "@/assets/svg/cta/open-icon.svg?inline";
import VideoLibrarySlidingModal from "@/components/shared/VideoLibrarySlidingModal.vue";
import EditIcon from "@/assets/svg/edit-icon.svg?inline";
import enums from "../../enums";
import { mapGetters } from "vuex";
import ArrowNext from "@/assets/svg/arrow-next-6.svg?inline";

const { FormatType } = enums;

export default {
  name: "DashboardTable",
  components: {
    ArrowBackIcon,
    Slideshow,
    MultiVideoIcon,
    MultiCampaignIcon,
    ArrowNext,
    OpenIcon,
    VideoLibrarySlidingModal,
    EditIcon,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedCampaign: "",
      FormatType,
      modalData: {
        openVideoLibrarySlidingModal: false,
        fileName: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      getCurrency: "site/getCurrency",
    }),
  },
  methods: {
    displayFormatType(format) {
      if (format.isStickyBar) {
        return "Sticky";
      } else if (format.formatType == FormatType.popup) {
        return "Popup";
      } else if (format.formatType == FormatType.embed) {
        return "Embed";
      } else if (format.formatType == FormatType.bubble) {
        return "Bubble";
      } else if (format.formatType == FormatType.carousel) {
        return "Carousel";
      }
    },
    handleOpenVideoLibrarySlidingModal(row) {
      this.modalData = {
        openVideoLibrarySlidingModal: true,
        fileName: row.fileName,
      };
      this.$emit("video-library-opened");
    },
    closeVideoLibrary() {
      this.modalData = {
        openVideoLibrarySlidingModal: false,
        fileName: "",
      };
      this.$emit("video-library-closed");
    },
    async editCampaign(campaign) {
      await this.$store.dispatch("campaign/setCampaignSelected", campaign._id);
      this.$router.push({
        name: campaign.bulk ? "editBulkEmbed" : "editCampaign",
        params: { campaignId: campaign._id },
        query: { embed: campaign.formats[0].formatType === FormatType.embed },
      });
    },
    getFeedNumber(index) {
      let count = 0;
      for (let i = 0; i < this.selectedCampaign.bulk.length; i++) {
        if (index < count + this.selectedCampaign.bulk[i].videos.length) {
          return index === count ? i + 1 : "";
        }
        count += this.selectedCampaign.bulk[i].videos.length;
      }
    },
    isGroupBoundary(index) {
      if (!this.selectedCampaign.bulk) return false;
      let count = 0;
      for (let i = 0; i < this.selectedCampaign.bulk.length; i++) {
        count += this.selectedCampaign.bulk[i].videos.length;
        if (index + 1 === count) {
          return true;
        }
      }
      return false;
    },
    currencyDisplay(amount) {
      if (this.getCurrency === "$") {
        return `${this.getCurrency}${amount}`;
      } else {
        return `${amount}${this.getCurrency}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .title {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .bold {
    font-weight: $w-bold;
  }
  .link {
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid $dark-grey;
    border-radius: 2px;
    font-size: 14px;
    color: $dark-grey;
    padding: 4px 12px;
    height: 14px;
    text-decoration: none;
    cursor: pointer;
  }
}
.back-icon {
  display: flex;
  gap: 10px;
  background: white;
  align-items: center;
  path {
    fill: black;
  }
  p {
    font-size: 14px;
    font-weight: 500;
  }
}
.data-table {
  width: 100%;
  table th:first-child {
    border-radius: 4px 0 0 4px;
  }

  table th:last-child {
    border-radius: 0 4px 4px 0;
  }
  table {
    margin: auto;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    tr {
      max-height: 100%;
      border-radius: 5px;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }
    th {
      padding: 8px 16px;
      font-weight: 500;
      background: #ececec;
      white-space: nowrap;
      text-align: end;
    }
    th.video-title {
      text-align: start;
    }
    th.campaign-name {
      text-align: start;
    }

    td {
      padding: 16px;
      text-align: end;
      font-weight: 500;
    }
    td.feed-separator-wrapper {
      padding: 0;
    }
    td > p {
      white-space: nowrap;
      font-weight: 500;
    }

    .row:hover {
      background: linear-gradient(
        270deg,
        #f2d9fc 11.86%,
        rgba(242, 217, 252, 0) 100%
      );
      cursor: pointer;
      outline: 1px solid #dcdbdb;
    }
    .row:hover .actions {
      display: flex;
      justify-content: flex-end;
      gap: 5px;
      align-items: center;
    }
    .row:hover .revenue {
      display: none;
    }

    .row td {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }

    .action-column {
      border-radius: 0px 5px 5px 0px;
    }

    td.hidden {
      visibility: hidden;
    }
    .actions {
      display: none;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      svg {
        margin-left: 5px;
        path {
          fill: black;
        }
      }
    }

    .actions:hover {
      cursor: pointer;
    }
  }
}

.slide-leave-active,
.slide-enter-active {
  opacity: 1;
  transition: 1s;
}
.slide-enter {
  opacity: 0;
  transform: translate(100%, 0);
}
.slide-leave-to {
  opacity: 0;
  transform: translate(-100%, 0);
}

.slideback-leave-active,
.slideback-enter-active {
  opacity: 1;
  transition: 1s;
}
.slideback-enter {
  opacity: 0;
  transform: translate(-100%, 0);
}
.slideback-leave-to {
  opacity: 0;
  transform: translate(100%, 0);
}

.name-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.boxes {
  display: flex;
  gap: 10px;
  width: 50%;
  justify-content: flex-start;
}
.boxes p {
  display: flex;
  gap: 10px;
  text-align: center;
  background: rgba(217, 217, 217, 0.1);
  border: 1.5px solid $light-grey;
  border-radius: 4px;
  color: $medium-grey;
  min-width: fit-content;
  padding: 5px 10px;
}

.campaign-name {
  text-align: left;
  width: 44%;
}

.column {
  width: 15%;
}
.big-column {
  width: 30%;
}
.small-column {
  width: 5%;
  p {
    margin: 0 !important;
  }
}

.name {
  max-width: 40%;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 18px;
}
.video-name {
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 !important;
  text-align: left;
  line-height: 18px;
}

.open-icon {
  cursor: pointer;
  margin-right: 4px;
}

.feed-separator {
  margin: 0;
}
</style>
