<template>
  <div class="cards-wrapper">
    <div v-for="(card, index) in cards" :key="index" class="use-case">
      <div
        class="card-use-case"
        :class="{ active: activeCard === index }"
        @click="selectCard(index)"
      >
        <div class="description">
          <h3 class="card-title">{{ card.title }}</h3>
          <p class="card-text">{{ card.text }}</p>
        </div>
        <div class="use-case-image-wrapper">
          <img :src="card.image" alt="use-case" class="use-case-image" />
        </div>
      </div>
      <p class="link" :style="{ display: 'none' }">See examples</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeCard: null,
      cards: [
        {
          title: this.$t("widgetCreator.useCases.productsTitle"),
          text: this.$t("widgetCreator.useCases.productsText"),
          image: require("@/assets/product-pages-use-case.png"),
        },
        {
          title: this.$t("widgetCreator.useCases.homePageTitle"),
          text: this.$t("widgetCreator.useCases.homePageText"),
          image: require("@/assets/homepage-use-case.png"),
        },
        {
          title: this.$t("widgetCreator.useCases.othersTitle"),
          text: this.$t("widgetCreator.useCases.othersText"),
          image: require("@/assets/other-use-case.png"),
        },
      ],
    };
  },
  methods: {
    selectCard(index) {
      this.activeCard = index;
      this.$emit("card-selected", index);
    },
  },
};
</script>

<style lang="scss" scoped>
.cards-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
}

.use-case {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.card-use-case {
  width: 300px;
  height: 430px;
  border: 2px solid $light-grey;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgb(246, 246, 246);
  }

  &.active {
    border: 2px solid $dark-purple;
    background-color: $light-pink;
  }
}

.description {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}

.card-title {
  @include base-font;
  @include font-big;
  font-weight: 700;
}

.card-text {
  @include base-font;
  @include font-small;
}

.use-case-image-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.use-case-image {
  width: 100%;
  height: 100%;
}

.link {
  @include base-font;
  @include font-smaller;
  text-decoration: underline;
  color: $dark-purple;
  cursor: pointer;
}
</style>
