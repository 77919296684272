<template>
  <div class="cards-wrapper">
    <div
      v-for="(card, index) in cards"
      :key="index"
      class="card-format"
      :class="{ active: activeCard === index }"
      @click="card.keyword === 'gallery' ? clickOnGallery() : selectCard(index)"
    >
      <div class="description">
        <h3 class="card-title">{{ card.title }}</h3>
        <p class="card-text">{{ card.text }}</p>
      </div>
      <div class="format-image-wrapper">
        <img :src="card.image" alt="format" class="format-image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeCard: null,
      cards: [
        {
          title: "Quick shop carousel",
          text: "One-click checkout & add-to-cart.",
          image: require("@/assets/format-images/quick-shop-carousel.png"),
          keyword: "quickShop",
        },
        {
          title: "Stories",
          text: "Videos open in full screen.",
          image: require("@/assets/format-images/stories.png"),
          keyword: "stories",
        },
        {
          title: "Carousel inline",
          text: "Videos play inside their frame.",
          image: require("@/assets/format-images/carousel-inline.png"),
          keyword: "carouselInline",
        },
        {
          title: "Carousel fullscreen",
          text: "Videos open in full screen.",
          image: require("@/assets/format-images/carousel-fullscreen.png"),
          keyword: "carouselFullscreen",
        },
        {
          title: "Bubble",
          text: "Floating widget, with triggers.",
          image: require("@/assets/format-images/bubble.png"),
          keyword: "bubble",
        },
        {
          title: "Popup",
          text: "Floating widget, with triggers.",
          image: require("@/assets/format-images/popup.png"),
          keyword: "popup",
        },
        {
          title: "Classic embed",
          text: "Just like Vimeo, but responsive.",
          image: require("@/assets/format-images/embed.png"),
          keyword: "embed",
        },
        {
          title: "In-gallery videos",
          text: "For themes without this feature.",
          image: require("@/assets/format-images/gallery.png"),
          keyword: "gallery",
        },
      ],
    };
  },
  methods: {
    selectCard(index) {
      this.activeCard = index;
      const selectedKeyword = this.cards[index].keyword;
      this.$emit("card-selected", selectedKeyword);
    },
    clickOnGallery() {
      window.Intercom &&
        window.Intercom(
          "showNewMessage",
          `Hi! I'm interested in embedding videos in image galleries.
A product page URL: https://...
Can you check if it's feasible on my website?`
        );
      window.Intercom &&
        window.Intercom(
          "showNewMessage",
          `Hi! I'm interested in embedding videos in image galleries.
A product page URL: https://...
Can you check if it's feasible on my website?`
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  margin: 40px auto;
  max-width: calc(4 * 250px + 3 * 16px);
}

.card-format {
  width: 230px;
  height: 270px;
  border: 2px solid $light-grey;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgb(246, 246, 246);
  }

  &.active {
    border: 2px solid $dark-purple;
    background-color: $light-pink;
    .description {
      background-color: $light-pink;
    }
  }
}

.description {
  height: 60px;
  background-color: rgb(246, 246, 246);
  border-radius: 10px 10px 0 0;
  padding: 12px;
  box-sizing: border-box;
}

.card-title {
  @include base-font;
  @include font-small;
  font-weight: 600;
  margin-bottom: 4px;
}

.card-text {
  @include base-font;
  @include font-smaller;
  font-weight: 500;
  color: rgb(163, 163, 163);
}

.format-image-wrapper {
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
