<template>
  <section class="step-1-wrapper">
    <div class="step-1-title">
      <h1>{{ $t("create.step1.heading") }}</h1>
    </div>
    <button class="close-icon" @click="backToCampaignManager">
      <close-icon> </close-icon>
    </button>
    <div class="step-1-content">
      <div class="usecase-card-section">
        <div class="format-wrapper">
          <div class="format-title">
            <h2>{{ $t("create.step1.fullScreen") }}</h2>
            <img
              v-tippy="{
                arrow: true,
                placement: 'right',
                a11y: false,
                maxWidth: 450,
                boundary: 'window',
              }"
              src="@/assets/usecases_cards/tooltip-icon.png"
              alt="tooltip-icon"
              class="tooltip-icon"
              :content="$t('create.step1.tooltip.fullScreen')"
            />
          </div>
          <div class="use-case-card-container">
            <div
              v-for="{
                text,
                imageSrc,
                goal,
                backside,
                tag,
                videoSrc,
                index,
              } in fullScreenFormats"
              :key="index"
            >
              <use-case-card
                :image-location="imageSrc"
                :selected="selectedCardImageSrc === imageSrc"
                :tag-location="tag"
                @card-selected="
                  selectCard(imageSrc);
                  changeVideoSource(videoSrc);
                "
              >
                <template #title>{{ text }}</template>
                <template #backside>
                  <div class="card-text">
                    <p>{{ backside }}</p>
                  </div>
                </template>
              </use-case-card>
            </div>
          </div>
        </div>
        <div class="format-wrapper">
          <div class="format-title">
            <h2>{{ $t("create.step1.inlineVideos") }}</h2>
            <img
              v-tippy="{
                arrow: true,
                placement: 'right',
                a11y: false,
                maxWidth: 450,
                boundary: 'window',
              }"
              src="@/assets/usecases_cards/tooltip-icon.png"
              alt="tooltip-icon"
              class="tooltip-icon"
              :content="$t('create.step1.tooltip.inlineVideos')"
            />
          </div>
          <div class="use-case-card-container">
            <div
              v-for="{
                text,
                imageSrc,
                goal,
                backside,
                tag,
                videoSrc,
                index,
              } in inlineFormats"
              :key="index"
            >
              <use-case-card
                :key="text"
                :image-location="imageSrc"
                :send-to-goal="goal"
                :selected="selectedCardImageSrc === imageSrc"
                :tag-location="tag"
                @card-selected="
                  selectCard(imageSrc);
                  changeVideoSource(videoSrc);
                "
              >
                <template #title>{{ text }}</template>
                <template #backside>
                  <div class="card-text">
                    <p>{{ backside }}</p>
                  </div>
                </template>
              </use-case-card>
            </div>
          </div>
        </div>
      </div>
      <div class="example-video-section">
        <div class="video-with-image">
          <img
            v-if="selectedCardImageSrc"
            v-tippy="{
              arrow: true,
              placement: 'top',
              a11y: false,
              maxWidth: 450,
            }"
            src="@/assets/usecases_cards/example-customer.png"
            alt="customer-example"
            :content="$t('create.step1.tooltip.examples')"
          />
          <img
            v-else
            src="@/assets/usecases_cards/select-card.png"
            alt="select-card"
          />
          <div v-show="selectedVideoSrc" class="card-selected">
            <video
              ref="video"
              width="100%"
              height="100%"
              autoplay
              loop
              playsinline
              muted
            >
              <source :src="selectedVideoSrc" type="video/mp4" />
            </video>
          </div>
          <div v-if="!selectedVideoSrc" class="no-card-selected">
            <div
              class="vidjet-video-wrapper"
              style="
                padding-bottom: 58.25%;
                position: relative;
                overflow: hidden;
                height: auto;
                margin: 0px auto;
              "
            >
              <iframe
                class="vidjet-embed-iframe"
                src="https://player.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/44f4c327-00fe-4e06-86ee-0ad5824654de"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  border-radius: 10px;
                "
                allow="clipboard-read; clipboard-write; fullscreen"
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </div>

        <SettingsFooter
          :show-back-btn="false"
          class="footer"
          @click.native="toCreateCampaignStep2"
        />
      </div>
    </div>
    <RegularOrBulkModal
      v-if="showBulkModal"
      @close-bulk-modal="closeBulkModal"
      @choose-bulk-option="chooseBulkOption"
    />
  </section>
</template>

<script>
import CloseIcon from "@/assets/svg/settings/close.svg?inline";
import BubbleCard from "@/assets/usecases_cards/bubble-fs.png";
import BubbleCardInline from "@/assets/usecases_cards/bubble-in.png";
import PopupCard from "@/assets/usecases_cards/popup-in.png";
import Stories from "@/assets/usecases_cards/stories-fs.png";
import EmbedCard from "@/assets/usecases_cards/embedded-in.png";
import UseCaseCard from "../shared/UseCaseCard.vue";
import Carousel from "@/assets/usecases_cards/carousel-fs.png";
import CarouselCardInline from "@/assets/usecases_cards/carousel-in.png";
import Sticky from "@/assets/usecases_cards/sticky-in.png";
import SettingsFooter from "./SettingsFooter.vue";
import RegularOrBulkModal from "@/components/create-campaign-steps/RegularOrBulkModal.vue";
import { mapGetters, mapState } from "vuex";
import api from "@/api";
import { tagMixin } from "@/utils/add-bulk-tag.js";
import enums from "../../enums";

const { FormatType, DeviceType } = enums;

export default {
  components: {
    CloseIcon,
    UseCaseCard,
    SettingsFooter,
    RegularOrBulkModal,
  },
  mixins: [tagMixin],
  props: {
    productId: {
      type: Number,
      String,
      default: null,
    },
  },
  data() {
    return {
      fullScreenFormats: [
        {
          text: "Bubble",
          imageSrc: BubbleCard,
          goal: { goal: "fullscreen-bubble", type: "fullScreen" },
          backside: this.$t("create.step1.bubbleFullScreen"),
          tag: "no-code-tag.svg",
          videoSrc:
            "https://media.vidjet.io/format-preview/Full+screen+bubble+KW+cropped.mp4",
        },
        {
          text: "Stories",
          imageSrc: Stories,
          goal: { goal: "stories", type: "fullScreen" },
          backside: this.$t("create.step1.stories"),
          tag: "embed-tag.svg",
          videoSrc:
            "https://media.vidjet.io/format-preview/Stories+KW+cropped.mp4",
        },
        {
          text: "Carousel",
          imageSrc: Carousel,
          goal: { goal: "carousel", type: "fullScreen" },
          backside: this.$t("create.step1.carouselFullScreen"),
          tag: "embed-tag.svg",
          videoSrc:
            "https://media.vidjet.io/format-preview/Full+screen+carousel+KW+cropped.mp4",
        },
      ],
      inlineFormats: [
        {
          text: "Bubble",
          imageSrc: BubbleCardInline,
          goal: { goal: "bubble", type: "inline" },
          backside: this.$t("create.step1.bubbleInline"),
          tag: "no-code-tag.svg",
          videoSrc:
            "https://media.vidjet.io/format-preview/Inline+bubble+cropped.mp4",
        },
        {
          text: "Embed",
          imageSrc: EmbedCard,
          goal: { goal: "embed", type: "inline" },
          backside: this.$t("create.step1.embedded"),
          tag: "embed-tag.svg",
          videoSrc:
            "https://media.vidjet.io/format-preview/Embed+Kw+cropped.mp4",
        },
        {
          text: "Carousel",
          imageSrc: CarouselCardInline,
          goal: { goal: "carousel", type: "inline" },
          backside: this.$t("create.step1.carouselInline"),
          tag: "embed-tag.svg",
          videoSrc:
            "https://media.vidjet.io/format-preview/Carousel+inline+KW+cropped.mp4",
        },
        {
          text: "Popup",
          imageSrc: PopupCard,
          goal: { goal: "popup", type: "inline" },
          backside: this.$t("create.step1.popup"),
          tag: "no-code-tag.svg",
          videoSrc:
            "https://media.vidjet.io/format-preview/Pop+up+KW+cropped.mp4",
        },
        {
          text: "Sticky Play",
          imageSrc: Sticky,
          goal: { goal: "sticky", type: "inline" },
          backside: this.$t("create.step1.stickyPlay"),
          tag: "no-code-tag.svg",
          videoSrc:
            "https://media.vidjet.io/format-preview/Sticky+play+cropped.mp4",
        },
      ],
      formatSelected: false,
      selectedCardImageSrc: null,
      selectedVideoSrc: null,
      videoChange: false,
      showBulkModal: false,
      campaign: {},
      name: {
        en: "From scratch",
        fr: "Personnalisée",
      },
    };
  },
  computed: {
    ...mapState({
      products: (state) => state.product.products,
    }),
    ...mapGetters({
      siteId: "account/getSiteId",
      defaultStoryFormatObject: "campaign/defaultStoryFormatObject",
      defaultCarouselFormatObject: "campaign/defaultCarouselFormatObject",
      defaultFormatObject: "campaign/defaultFormatObject",
      defaultEmbedObject: "campaign/defaultEmbedFormatObject",
      defaultBubbleProperties: "campaign/defaultBubbleProperties",
      getTags: "tag/getTags",
    }),
    allFormatsArray() {
      return this.fullScreenFormats.concat(this.inlineFormats);
    },
    comesFromProductPages() {
      return this.$route.query.action === "ProductPageWidgetCreation";
    },
    comesFromVideoLibrary() {
      return (
        this.$route.query.action ===
          "VideoLibraryCampaignManagerWidgetCreation" ||
        this.$route.query.action === "VideoLibraryDashboardWidgetCreation" ||
        this.$route.query.action === "VideoLibraryProductPagesWidgetCreation"
      );
    },
    productName() {
      const product = this.products.find(
        (product) => product.productId === this.productId
      );
      return product ? product.name : "Feed name 1";
    },
    i18nLocale() {
      return this.$i18n.locale ? this.$i18n.locale : "en";
    },
  },
  async created() {
    if (this.comesFromProductPages || this.comesFromVideoLibrary) {
      await this.$store.dispatch("product/getProducts", {
        siteId: this.siteId,
        isProductListView: false,
      });
    }
  },
  methods: {
    backToCampaignManager() {
      this.$router.push({ name: "campaignManager" });
    },
    selectCard(imageSrc) {
      if (imageSrc === this.selectedCardImageSrc) {
        this.selectedCardImageSrc = null;
      } else {
        this.selectedCardImageSrc = imageSrc;
      }
    },
    async toCreateCampaignStep2() {
      const selectedCard = this.allFormatsArray.find(
        (card) => card.imageSrc === this.selectedCardImageSrc
      );

      if (!selectedCard) {
        return this.$notify({
          text: this.$t("create.step1.selectCard"),
          type: "error",
        });
      }

      const { goal, type } = selectedCard.goal;

      //Let user choose between regular and bulk option for product page
      if (
        !this.comesFromProductPages &&
        !this.comesFromVideoLibrary &&
        goal !== "sticky"
      ) {
        return (this.showBulkModal = true);
      }

      if (this.comesFromProductPages || this.comesFromVideoLibrary) {
        const formats = this.getFormatsObject({ goal, type });
        const tag = await this.addBulkTag();
        const campaignData = {
          formats,
          bulk: [],
          name: "Bulk Embed",
          siteId: this.siteId,
          tags: [tag._id],
          stateFlag: 1,
        };
        if (this.comesFromProductPages) {
          try {
            this.campaign = await api.createCampaign(campaignData);
            this.$router.push({
              name: "productPages",
              query: {
                campaignId: this.campaign._id,
                productId: this.$route.query.productId,
              },
            });
          } catch (error) {
            console.error("API call error", error);
            this.$notify({
              text: this.$t("create.step1.creationError"),
              type: "error",
            });
          }
        } else if (this.comesFromVideoLibrary) {
          let url = "";
          const action = this.$route.query.action;
          if (action === "VideoLibraryCampaignManagerWidgetCreation") {
            url = "campaignManager";
          } else if (action === "VideoLibraryDashboardWidgetCreation") {
            url = "dashboard";
          } else if (action === "VideoLibraryProductPagesWidgetCreation") {
            url = "productPages";
          }
          try {
            this.campaign = await api.createCampaign(campaignData);
            this.$router.push({
              name: url,
              query: {
                campaignId: this.campaign._id,
                productId: this.$route.query.productId,
                action: "videoLibraryCreation",
              },
            });
          } catch (error) {
            console.error("API call error", error);
            this.$notify({
              text: this.$t("create.step1.creationError"),
              type: "error",
            });
          }
        }
      } else {
        this.initCampaign();
        this.$router.push({
          name: "createCampaignStep3",
          query: selectedCard.goal,
        });
      }
    },
    changeVideoSource(videoSrc) {
      this.selectedVideoSrc =
        this.selectedVideoSrc === videoSrc ? null : videoSrc;
      const video = this.$refs.video;
      video.src = videoSrc;
      video.load();
      video.play();
    },
    closeBulkModal() {
      this.showBulkModal = false;
    },
    chooseBulkOption(isBulk) {
      const selectedCard = this.allFormatsArray.find(
        (card) => card.imageSrc === this.selectedCardImageSrc
      );

      if (isBulk) {
        return this.$router.push({
          name: "createBulkEmbed",
          query: selectedCard.goal,
        });
      }
      this.initCampaign();
      this.$router.push({
        name: "createCampaignStep3",
        query: selectedCard.goal,
      });
    },
    getFormatsObject({ goal, type }) {
      switch (goal) {
        case "carousel":
          if (type === "fullScreen")
            return [
              {
                ...this.defaultFormatObject,
                ...this.defaultCarouselFormatObject,
                isFullScreen: true,
                carouselThumbnailAmount: 1,
              },
            ];
          return [
            {
              ...this.defaultFormatObject,
              ...this.defaultCarouselFormatObject,
              isFullScreen: false,
            },
          ];
        case "stories":
          return [
            {
              ...this.defaultFormatObject,
              ...this.defaultStoryFormatObject,
            },
          ];
        case "embed":
          return [
            {
              ...this.defaultFormatObject,
              ...this.defaultEmbedObject,
            },
          ];
        case "bubble":
          return [
            {
              ...this.defaultFormatObject,
              ...this.defaultBubbleProperties,
              deviceType: DeviceType.mobile,
            },
            {
              ...this.defaultFormatObject,
              ...this.defaultBubbleProperties,
              deviceType: DeviceType.desktop,
            },
          ];
        case "fullscreen-bubble":
          return [
            {
              ...this.defaultFormatObject,
              ...this.defaultBubbleProperties,
              isFullScreen: true,
              deviceType: DeviceType.mobile,
            },
            {
              ...this.defaultFormatObject,
              ...this.defaultBubbleProperties,
              isFullScreen: true,
              deviceType: DeviceType.desktop,
            },
          ];
        case "popup":
          return [
            {
              ...this.defaultFormatObject,
              formatType: FormatType.popup,
              deviceType: DeviceType.mobile,
            },
            {
              ...this.defaultFormatObject,
              formatType: FormatType.popup,
              deviceType: DeviceType.desktop,
            },
          ];
      }
    },
    async initCampaign() {
      const dataToUpdate = {
        campaignName: this.name[this.i18nLocale],
        stateFlag: 1,
      };

      this.$store.commit("campaign/campaignCreationInit", {
        dataToUpdate,
      });
      this.$store.commit("campaign/campaignCreationSetVideos", []);
    },
  },
};
</script>

<style lang="scss" scoped>
.step-1-title {
  margin-bottom: 32px;
}
.card-text {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 85%;
  height: 100%;
  margin: auto;
}
.step-1-wrapper {
  height: 100vh;
  box-sizing: border-box;
  padding-top: 32px;
  text-align: center;
}

.close-icon {
  position: absolute;
  background: none;
  right: $close-icon-margin;
  top: $close-icon-margin;
}

.step-1-content {
  height: 80%;
  display: flex;
  padding: 0;
}

.format-wrapper {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 0 auto;
}
.format-title {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0;
  margin-bottom: 16px;
  @media (min-width: 1800px) {
    margin-bottom: 24px;
  }
  h2 {
    font-size: 24px;
    line-height: 27px;
    color: $dark-grey;
    &:hover {
      cursor: default;
    }
  }
  .tooltip-icon {
    width: 15px;
    &:hover {
      cursor: pointer;
    }
  }
}
.usecase-card-section {
  width: 40%;
  height: 82vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  overflow-y: auto;
}

.use-case-card-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(230px, 1fr));
  margin: 0 auto 24px;
  flex-wrap: wrap;
  gap: 16px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(1, minmax(300px, 1fr));
    gap: 12px;
  }
  @media (min-width: 1800px) {
    gap: 24px;
  }
}

.footer {
  border-radius: 5px;
  overflow-x: hidden;
  width: 48%;
  margin: 0 auto;
  position: fixed;
  bottom: 64px;
}

.example-video-section {
  @include flex-centered;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
  img {
    margin: 0 auto;
  }
}
.video-with-image {
  width: 100%;
}
.no-card-selected {
  width: 80%;
  margin: 0 auto;
  aspect-ratio: 4 / 2.31;
}

.card-selected {
  width: 80%;
  margin: 0 auto;
  aspect-ratio: 4 / 2.31;
  @media (max-height: 600px) {
    height: 280px;
    margin-bottom: 16px;
  }
}
</style>
