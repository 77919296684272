// This file is to export all the components for the library so we can use dynamic imports in standalone
import Home from "./views/Home";
import BubbleFormat from "./formats/BubbleFormat.vue";
import VideoPlayer from "./components/VideoPlayer.vue";
import SpinnerLoader from "./components/shared/SpinnerLoader.vue";
import CarouselFormat from "./formats/CarouselFormat.vue";
import CloseButton from "./components/player-components/CloseButton.vue";
import AddToCartButton from "./components/player-components/AddToCartButton.vue";
import VidjetWatermark from "./components/player-components/VidjetWatermark.vue";
import PlayerTitle from "./components/player-components/PlayerTitle.vue";
import PlayerText from "./components/player-components/PlayerText.vue";
import CtaButton from "./components/player-components/CtaButton.vue";
import CustomInput from "./components/player-components/CustomInput.vue";
import VideoControls from "./components/player-components/VideoControls.vue";
import AtcForm from "./components/add-to-cart/AtcForm.vue";
import PlayPause from "./components/player-components/PlayPauseControls.vue";
import PlayerInterface from "./components/PlayerInterface.vue";
import NavigationArrows from "./components/additional-components/NavigationArrows.vue";
import AtcMultipleProducts from "./components/add-to-cart/AtcMultipleProducts.vue";
import BubblePlayer from "./components/BubblePlayer.vue";

import CarouselArrow from "@/components/arrows/Arrow.vue";
import ArrowCorner1 from "@/components/arrows/corner1.vue";
import ArrowCorner2 from "@/components/arrows/corner2.vue";
import ArrowCorner3 from "@/components/arrows/corner3.vue";
import ArrowLine1 from "@/components/arrows/line1.vue";
import ArrowLine2 from "@/components/arrows/line2.vue";
import ArrowLine3 from "@/components/arrows/line3.vue";
import ArrowBold1 from "@/components/arrows/bold1.vue";
import ArrowBold2 from "@/components/arrows/bold2.vue";
import ArrowBold3 from "@/components/arrows/bold3.vue";

import ArrowBack from "./assets/arrow-back.svg";
import ArrowDown from "./assets/arrow-down.svg";
import ArrowHorizontal from "./assets/arrow-horizontal.svg";
import Arrow from "./assets/arrow.svg";
import BagIcon from "@/assets/bag-icon.svg";
// import CheckIcon from "@/assets/check-icon.svg";
// import CloseIcon from "@/assets/close-small.svg";
//close
//guac
import ArrowHorizontalSmall from "@/assets/horizontal-right-arrow-small.svg"
import InfoIcon from "@/assets/information-circle.svg";
import MuteIcon from "@/assets/mute-icon.svg";
//plane
import Share from "./assets/share.svg";
//Shoping bag
import SoundOff from "@/assets/sound-off.svg";

import PauseMinimal from "@/assets/play-pause-icons/pause-minimal.vue";
import PauseOutline from "@/assets/play-pause-icons/pause-outline.vue";
import PauseRound from "@/assets/play-pause-icons/pause-round.vue";
import PauseSquare from "@/assets/play-pause-icons/pause-square.vue";
import PlayMinimal from "@/assets/play-pause-icons/play-minimal.vue";
import PlayOutline from "@/assets/play-pause-icons/play-outline.vue";
import PlayRound from "@/assets/play-pause-icons/play-round.vue";
import PlaySquare from "@/assets/play-pause-icons/play-square.vue";

import Vue from 'vue'
import visibility from 'vue-visibility-change';
import UUID from "vue-uuid";
import vSelect from "vue-select";
import { slide } from './utils/v-slide';
import { VidjetTooltip } from './utils/vidjet-tooltip';
import { playerStore } from "./store";
import hls from 'hls.js'

Vue.config.productionTip = false

// custom directives
Vue.directive('slide', slide);
Vue.directive('tooltip', VidjetTooltip);
Vue.directive(hls);

Vue.use(visibility);
Vue.use(UUID);

Vue.component("VSelect", vSelect);




export {
  Home,
  BubbleFormat,
  VideoPlayer,
  SpinnerLoader,
  CarouselFormat,
  CloseButton,
  AddToCartButton,
  VidjetWatermark,
  PlayerTitle,
  PlayerText,
  CtaButton,
  CustomInput,
  VideoControls,
  Share,
  AtcForm,
  Arrow,
  ArrowBack,
  ArrowDown,
  ArrowHorizontal,
  PlayPause,
  PlayerInterface,
  NavigationArrows,
  AtcMultipleProducts,
  BubblePlayer,

  CarouselArrow,
  ArrowCorner1,
  ArrowCorner2,
  ArrowCorner3,
  ArrowLine1,
  ArrowLine2,
  ArrowLine3,
  ArrowBold1,
  ArrowBold2,
  ArrowBold3,

  PlayMinimal,
  MuteIcon,
  SoundOff,
  PauseMinimal,
  PlayOutline,
  PauseOutline,
  PlayRound,
  PauseRound,
  PlaySquare,
  PauseSquare,
  ArrowHorizontalSmall,
  InfoIcon,
  BagIcon,
  // export store
  playerStore
};
