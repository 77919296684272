import campaign from "./campaign";
import campaignBulk from './campaign-bulk'
import user from "./user";
import account from "./account";
import site from "./site";
import dashboard from "./dashboard";
import integration from "./integration";
import invoices from "./invoices";
import plan from "./plan";
import usage from "./usage";
import issue from "./issue";
import logger from "./logger";
import suggestion from "./suggestion";
import video from "./video";
import tag from "./tag";
import product from "./product";

export default {
  //Campaign
  getCampaigns: campaign.getCampaigns,
  getSingleCampaign: campaign.getSingleCampaign,
  createCampaign: campaign.createCampaign,
  updateCampaign: campaign.updateCampaign,
  deleteCampaign: campaign.deleteCampaign,
  uploadVideo: campaign.uploadVideo,
  campaignScheduler: campaign.campaignScheduler,

  //Campaign Bulk
  createFeed: campaignBulk.createFeed,
  getFeed: campaignBulk.getFeed,
  updateFeed: campaignBulk.updateFeed,
  deleteFeed: campaignBulk.deleteFeed,
  addVideosToWidget: campaignBulk.addVideosToWidget,

  //User
  getUsers: user.getUsers,
  addUser: user.addUser,
  deleteUser: user.deleteUser,
  getUser: user.getUser,
  createShopifyUser: user.createShopifyUser,
  updateUser: user.updateUser,

  //Account
  signup: account.signup,
  appsumoSignup: account.appsumoSignup,
  login: account.login,
  updatePassword: account.updatePassword,
  setPassword: account.setPassword,
  resetPassword: account.resetPassword,
  cancelSubscription: account.cancelSubscription,
  processShopifyAuth: account.processShopifyAuth,
  logShopifyUser: account.logShopifyUser,
  logWixUser: account.logWixUser,
  logFromAuthToken: account.logFromAuthToken,
  logFromLocalStorage: account.logFromLocalStorage,

  //Site
  getSite: site.getSite,
  integrateShopifySite: site.integrateShopifySite,
  updateSite: site.updateSite,
  adminSearch: site.adminSearch,
  getSiteAgency: site.getSiteAgency,
  addClient: site.addClient,
  deleteClient: site.deleteClient,
  getIntegrationUrlGoogle: site.getIntegrationUrlGoogle,
  getIntegration: site.getIntegration,
  getIntegrations: site.getIntegrations,
  integrateKlaviyo: site.integrateKlaviyo,
  integrateInstagram: site.integrateInstagram,
  deleteIntegration: site.deleteIntegration,
  refreshCache: site.refreshCache,

  //Dashboard
  getDashboardGlobalMetrics: dashboard.getDashboardGlobalMetrics,
  getDashboardGraphMetrics: dashboard.getDashboardGraphMetrics,
  getDashboardCampaignMetrics: dashboard.getDashboardCampaignMetrics,
  getAgencyDashboardGlobalMetrics: dashboard.getAgencyDashboardGlobalMetrics,
  getAgencyDashboardGraphMetrics: dashboard.getAgencyDashboardGraphMetrics,

  //Integration
  hasCacheShopData: integration.hasCacheShopData,
  getWebsiteWithStore: integration.getWebsiteWithStore,
  terminateShopifyIntegration: integration.terminateShopifyIntegration,
  getVideoImport: integration.getVideoImport,

  // invoices
  getInvoices: invoices.getInvoices,

  // plan
  getPlan: plan.getPlan,
  addCreditCard: plan.addCreditCard,
  updatePlan: plan.updatePlan,
  removeWatermark: plan.removeWatermark,
  getProposedPlan: plan.getProposedPlan,
  reactivateSubscription: plan.reactivateSubscription,

  // usage
  getUsage: usage.getUsage,

  //issue
  sendIssue: issue.sendIssue,

  //logs
  sendLog: logger.sendLog,

  //issue
  sendSuggestion: suggestion.sendSuggestion,

  //video
  createVideo: video.createVideo,
  updateVideo: video.updateVideo,
  getVideo: video.getVideo,
  getVideos: video.getVideos,
  getInstagramVideos: video.getInstagramVideos,
  getInstagramAccountInformation: video.getInstagramAccountInformation,
  deleteVideo: video.deleteVideo,

  //tag
  getTags: tag.getTags,
  createTag: tag.createTag,
  deleteTag: tag.deleteTag,

  //product
  getProductsAddToCart: product.getProductsAddToCart,
  createProduct: product.createProduct,
  deleteProduct: product.deleteProduct,
  updateProduct: product.updateProduct,
};
