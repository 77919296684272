<template>
  <div>
    <div
      :class="[
        'subscription-card',
        { 
          'current-plan': subscription.name === currentPlan,
        },
      ]"
      @click="handleCardClick"
    >
      <h4
        class="title"
        :class="{ 'current-plan-title': subscription.name === currentPlan }"
      >
        {{
          $t(
            `billing.accountPlan.subscription.${
              isAppsumo ? subscription : subscription.name
            }.title`
          )
        }}
      </h4>
      <p class="price">
        <span v-if="isAppsumo">{{
          $t(`billing.accountPlan.subscription.${subscription}.price`)
        }}</span>
        <span v-else-if="isCustomPlan">{{
          $t(`billing.accountPlan.subscription.enterprise.price`)
        }}</span>
        <span v-else
          >${{ subscription.cost
          }}<span class="price-detail">{{
            $t("account.usage.month")
          }}</span></span
        >
      </p>
      <div class="features">
        <ul>
          <li v-if="!isAppsumo">
            ✔ {{ subscription.videosInCampaignsLimit || "Unlimited" }}
            {{ $t(`billing.accountPlan.globalSubscriptionFeatures.videos`) }}
          </li>
          <li v-if="!isAppsumo">
            ✔ {{ subscription.videoViewLimit || "Unlimited" }}
            {{ $t(`billing.accountPlan.globalSubscriptionFeatures.views`) }}
          </li>
          <li
            v-for="feature in $t(
              `billing.accountPlan.subscription.${
                isAppsumo ? subscription : subscription.name
              }.features`
            )"
            :key="feature.id"
          >
            {{ feature }}
          </li>
        </ul>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    subscription: {
      type: [Object, String],
      default() {
        return this.type === Object ? {} : "";
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({ 
      currentPlan: "plan/currentPlan",
      isTrial: "plan/userIsTrial",
      isPrestashop: "site/isPrestashop",
    }),

    isAppsumo() {
      return typeof this.subscription === "object"
        ? this.subscription.name.includes("vidjet_tier")
        : this.subscription.includes("vidjet_tier");
    },
    isCustomPlan() {
      return this.subscription.name === "enterprise";
    },
  },
  methods: {
    handleCardClick() {
      this.$emit("card-clicked", this.subscription);
    },
  },
};
</script>

<style scoped lang="scss">
$subscription-card-width: 180px;
$subscription-card-height: 240px;

.subscription-card {
  background: white;
  width: $subscription-card-width;
  height: $subscription-card-height;
  border: 1px solid $dark-grey;
  box-sizing: border-box;
  border-radius: 5px;
  @include base-font;
  position: relative;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &:hover {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4);
  }
}

.plan-card-disable {
  cursor: not-allowed;
}

.current-plan {
  border: 1px solid $dark-purple;
  background-color: rgba(226, 124, 252, 0.2);
}

.title {
  font-weight: 500;
  color: $dark-grey;
  @include base-font;
  @include font-normal;
  font-size: 18px;
  margin: 15px 0;
  text-align: center;
}

.current-plan-title {
  color: $dark-purple;
}

.features {
  width: 100%;
  margin: 0 auto;
}

.features ul {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 8px;
  margin: 0 auto;
  width: 100%;
  li {
    margin-left: 16px;
  }
}

.before-tax {
  @include font-smallest;
  color: $dark-grey;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.price {
  @include font-big;
  text-align: center;
  margin-bottom: 16px;
  color: black;
}
.price-detail {
  color: $light-grey;
  @include font-small;
}
</style>
