<template>
  <div>
    <PagesSegmentationNav
      :selected-option="selectedOption"
      :number-of-products="segmentation?.productId?.length"
      :url-segmentation="segmentation?.url"
      @optionSelected="handleOptionSelected"
    />
    <div v-if="selectedOption === 'pageUrls'">
      <url-segmentation
        :url-schema="segmentation?.url"
        @input="updateUrlSegmentation"
      />
    </div>
    <div v-if="selectedOption === 'productPages'">
      <ProductSegmentation
        class="product-segmentation"
        :product-ids="segmentation?.productId"
        @update-product-segmentation="updateProductSegmentation"
      />
    </div>
  </div>
</template>

<script>
import PagesSegmentationNav from "@/components/create-campaign-steps/PagesSegmentationNav.vue";
import ProductSegmentation from "@/components/create-campaign-steps/ProductSegmentation.vue";
import UrlSegmentation from "@/components/shared/Url-segmentation.vue";

import { mapState, mapGetters } from "vuex";

export default {
  components: {
    PagesSegmentationNav,
    ProductSegmentation,
    UrlSegmentation,
  },
  props: {
    segmentation: {
      Type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedOption: "pageUrls",
    };
  },
  computed: {
    ...mapState({ site: "site" }),
    ...mapGetters({
      getSiteId: "account/getSiteId",
    }),
  },
  methods: {
    updateUrlSegmentation(payload) {
      this.$emit("updateSegmentation", { ...this.segmentation, ...payload });
    },
    updateProductSegmentation(productIds) {
      this.$emit("updateSegmentation", {
        ...this.segmentation,
        productId: productIds,
      });
    },
    handleOptionSelected(option) {
      this.selectedOption = option;
    },
  },
};
</script>

<style lang="scss" scoped>
.product-segmentation {
  margin-bottom: 24px;
}
</style>
