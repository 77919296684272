import Vue from "vue";
import Router from "vue-router";
import "regenerator-runtime";

import Dashboard from "./components/dashboard/Dashboard.vue";
import SignupPage from "./components/SignupPage.vue";
import AppSumoSignupPage from "./components/AppSumoSignupPage.vue";
import Login from "./components/Login.vue";
import CampaignManager from "./components/CampaignManager.vue";
import EditCampaign from "./components/EditCampaign.vue";
import AdminPage from "./components/AdminPage.vue";
import AgencyPortal from "./components/AgencyPortal.vue";
import Onboarding from "./components/Onboarding.vue";
import ProductsRouter from "./components/ProductsRouter";

import steps from "./components/create-campaign-steps";
import EmbedStep4 from "./components/create-campaign-steps/EmbedStep4.vue";
import MobileView from "./components/shared/MobileView.vue";
import BulkEmbed from "./components/create-campaign-steps/BulkEmbed.vue";

import ShopifyIntegration from "./components/Shopify-integration.vue";
import WixIntegration from "./components/Wix-integration.vue";
import InstagramIntegration from "./components/Instagram-integration.vue";

import Settings from "./components/settings/Settings.vue";
import Account from "./components/settings/Account.vue";
import Integration from "./components/settings/Integration.vue";
import CreateUsers from "./components/settings/CreateUsers.vue";
import Billing from "./components/settings/Billing.vue";

import store from "./store/index.js";

const {
  CreateCampaignStep1,
  CreateCampaignStep2,
  CreateCampaignStep3,
  CreateCampaignStep4,
  CreateCampaignStep5,
} = steps;

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    { path: "/", redirect: "dashboard" },
    {
      path: "/dashboard",
      component: Dashboard,
      name: "dashboard",
      props: (route) => ({
        action: route.query.action,
      }),
    },
    {
      path: "/",
      component: Dashboard,
      name: "home",
      redirect: "/dashboard",
    },
    {
      path: "/login",
      component: Login,
      name: "login",
    },
    {
      path: "/signup",
      component: SignupPage,
      name: "signup",
    },
    {
      path: "/signup/trusted-shops",
      component: SignupPage,
      name: "signup",
    },
    {
      path: "/appsumo-signup",
      component: AppSumoSignupPage,
      name: "appsumoSignup",
    },
    {
      path: "/create/campaign/step1",
      component: CreateCampaignStep1,
      name: "createCampaignStep1",
      props: { isEditMode: false },
    },
    {
      path: "/create/campaign/step2",
      component: CreateCampaignStep2,
      name: "createCampaignStep2",
      props: { isEditMode: false },
    },
    {
      path: "/create/campaign/step3",
      component: CreateCampaignStep3,
      name: "createCampaignStep3",
      props: { isEditMode: false },
    },
    {
      path: "/create/campaign/step4",
      component: CreateCampaignStep4,
      name: "createCampaignStep4",
      props: { isEditMode: false },
    },
    {
      path: "/create/embed/step4",
      component: EmbedStep4,
      name: "embedStep4",
      props: { isEditMode: false },
    },
    {
      path: "/create/campaign/step5",
      component: CreateCampaignStep5,
      name: "createCampaignStep5",
      props: { isEditMode: false },
    },
    {
      path: "/create/campaign/bulk-embed",
      component: BulkEmbed,
      name: "createBulkEmbed",
      props: { isEditMode: false },
    },
    {
      path: "/edit-campaign/bulk-embed/:campaignId",
      component: BulkEmbed,
      name: "editBulkEmbed",
      props: { isEditMode: true },
    },
    {
      path: "/campaign-manager/:campaignId?",
      component: CampaignManager,
      name: "campaignManager",
      props: (route) => ({
        action: route.query.action,
      }),
    },
    {
      path: "/edit-campaign/:campaignId",
      component: EditCampaign,
      name: "editCampaign",
    },
    {
      path: "/edit-campaign/:campaignId/video",
      component: CreateCampaignStep2,
      name: "editCampaignVideo",
      props: { isEditMode: true },
    },
    {
      path: "/edit-campaign/:campaignId/format",
      component: CreateCampaignStep3,
      name: "editCampaignFormat",
      props: { isEditMode: true },
    },
    {
      path: "/edit-campaign/:campaignId/segmentation",
      component: CreateCampaignStep4,
      name: "editCampaignSegmentation",
      props: { isEditMode: true },
    },
    {
      path: "/edit-campaign/:campaignId/embed/step4",
      component: EmbedStep4,
      name: "editEmbedStep4",
      props: { isEditMode: true },
    },
    {
      path: "/launch-campaign/:campaignId",
      component: CreateCampaignStep5,
      name: "launchCampaign",
      props: { isEditMode: true },
    },
    {
      path: "/create/campaign/step1",
      component: CreateCampaignStep1,
      name: "Step1FromProductPages",
      props: (route) => ({
        isEditMode: false,
        action: route.query.action,
        productId: route.query.productId,
      }),
    },
    {
      path: "/shopify/auth",
      component: ShopifyIntegration,
      name: "shopifyIntegration",
    },
    {
      path: "/wix/auth",
      component: WixIntegration,
      name: "wixIntegration",
    },
    {
      path: "/instagram/auth",
      component: InstagramIntegration,
      name: "instagramIntegration",
    },
    {
      path: "/admin",
      component: AdminPage,
      name: "adminPage",
    },
    {
      path: "/agency",
      component: AgencyPortal,
      name: "agency",
    },
    {
      path: "/not-supported-on-mobile",
      component: MobileView,
      name: "mobile-view",
    },
    {
      path: "/product-pages",
      component: ProductsRouter,
      name: "productPages",
      props: (route) => ({
        campaignId: route.query.campaignId,
        action: route.query.action,
      }),
    },
    {
      path: "/home",
      component: Onboarding,
      name: "onboarding",
    },
    {
      path: "/settings",
      component: Settings,
      children: [
        {
          path: "",
          redirect: "account", // default child path
        },
        {
          path: "/settings/account",
          name: "account",
          component: Account,
        },
        {
          path: "/settings/billing",
          name: "billing",
          component: Billing,
        },
        {
          path: "/settings/integration",
          name: "integration",
          component: Integration,
        },
        {
          path: "/settings/users",
          name: "users",
          component: CreateUsers,
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "login",
    "forgotPassword",
    "recoverPassword",
    "signup",
    "appsumoSignup",
    "auto",
    "shopifyIntegration",
    "wixIntegration",
    "mobile-view",
  ];
  const authRequired = !publicPages.includes(to.name);

  const authToken = Vue.$cookies.get("vidjet_authtoken") || to.query.authToken;

  if (!store.state.account.user && !authToken && authRequired) {
    next("/login");
  }
  next();
});

export default router;

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('./views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('./views/my-view')
//
// function lazyLoadView(AsyncView) {
//   const AsyncHandler = () => ({
//       component: AsyncView,
//       // A component to use while the component is loading.
//       loading: require('./views/loading.vue').default,
//       // A fallback component in case the timeout is exceeded
//       // when loading the component.
//       error: require('./views/timeout.vue').default,
//       // Delay before showing the loading component.
//       // Default: 200 (milliseconds).
//       delay: 400,
//       // Time before giving up trying to load the component.
//       // Default: Infinity (milliseconds).
//       timeout: 10000,
//   });

//   return Promise.resolve({
//       functional: true,
//       render(h, { data, children }) {
//           // Transparently pass any props or children
//           // to the view component.
//           return h(AsyncHandler, data, children)
//       },
//   })
// }
