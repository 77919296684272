<template>
  <div :class="['accordion-wrapper', { 'accordion-wrapper-open': !hasBorder }]">
    <button
      class="accordion-button"
      :class="{ disabled: disabled }"
      :aria-expanded="isOpen"
      :aria-controls="`collapse${_uid}`"
      :disabled="disabled"
      @click="toggleAccordion()"
    >
      <div :class="['accordion-title', { 'title-center': centeredTitle }]">
        <div class="title-wrapper">
          <slot name="title" class="title" />
          <tippy
            v-if="disabled"
            placement="top"
            arrow="true"
            interactive="true"
            touch="true"
            a11y="false"
          >
            <template #trigger>
              <lock class="lock-icon"></lock>
            </template>
            <slot name="tooltip" />
          </tippy>
        </div>
        <svg
          class="accordion-icon"
          :class="{ isOpen }"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 16 10"
          aria-hidden="true"
        >
          <path
            d="M15 1.2l-7 7-7-7"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </button>

    <div
      v-if="isOpen"
      :id="`collapse${_uid}`"
      class="accordion-content-wrapper"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import lock from "@/assets/svg/lock-locked.svg?inline";
export default {
  components: {
    lock,
  },
  props: {
    isOpenByDefault: {
      type: Boolean,
      default: false,
    },
    hasBorder: {
      type: Boolean,
      default: true,
    },
    centeredTitle: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },

  watch: {
    isOpenByDefault(newValue) {
      this.isOpen = newValue;
    },
  },

  mounted() {
    this.isOpen = this.isOpenByDefault;
  },

  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
<style lang="scss" scoped>
.disabled {
  cursor: not-allowed;
}
.isOpen {
  transform: rotate(180deg);
  transition: all 0.1s;
}
.accordion-wrapper {
  border-bottom: 1px solid $light-grey;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
}

.accordion-button {
  background: none;
  @include base-font;
  color: black;
  width: 100%;
  @include font-small;
  font-weight: $w-normal;
  padding: 0;
}
.accordion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title-center {
  justify-content: center;
  gap: 16px;
}

.accordion-icon {
  transition: all 0.1s;
  width: 13px;
  height: 7px;
}

p {
  @include font-smaller;
  font-weight: $w-normal;
}

.accordion-wrapper-open {
  border: none;
  cursor: default;
  padding-bottom: 16px;
}

.title-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  text-align: left;
  
  svg {
    height: 14px;
  }

  path {
    fill: $dark-purple;
  }
}
</style>
