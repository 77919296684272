<template>
  <div class="carousel-card">
    <div class="card-header">
      <div class="icon-account">
        <Account />
        <h3 class="card-title">{{ $t("billing.accountPlan.title") }}</h3>
      </div>
      <ConversionBlock class="conversion-block" />
      <div v-if="userIsTrial" class="days-left-trial">
        <h3 class="text">
          {{ daysLeftTrial }} {{ $t("billing.accountPlan.daysLeftTrial") }}
        </h3>
      </div>
    </div>
    <div class="carousel-wrapper">
      <subscription-carousel
        :trigger-browse="hideButton"
        :subscription-array="subscriptionArray"
        @card-clicked="handleSlideClicked"
      ></subscription-carousel>
      <upgrade-plan
        v-if="showUpgradePlan"
        :plan-chosen="planChosen"
        :user-is-downgrading="userIsDowngrading"
        @closeUpgradePlan="closeUpgradePlan"
      />
    </div>
  </div>
</template>

<script>
import UpgradePlan from "../../upgrade-plan/UpgradePlan.vue";
import Account from "@/assets/svg/settings/account-plan.svg?inline";
import SubscriptionCarousel from "./SubscriptionCarousel";
import { mapState, mapGetters } from "vuex";
import planCriteria from "@/utils/plan-criteria.js";
import endOfToday from "date-fns/end_of_today";
import ConversionBlock from "./ConversionBlock.vue";

export default {
  components: {
    SubscriptionCarousel,
    Account,
    UpgradePlan,
    ConversionBlock,
  },
  props: {
    isAppsumo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showUpgradePlan: false,
      planChosen: "",
      subscriptionArray: [],
      // hideButton is to display 'Your current plan'triggers browse event in child
      hideButton: false,
      proposedPlan: {},
    };
  },
  computed: {
    ...mapState({
      dashboardData: (state) => state.dashboard.dashboardGlobalMetrics,
      dashboardDataForLast7Days: (state) =>
        state.dashboard.dashboardGlobalMetricsforLast7Days,
      plan: (state) => state.plan,
    }),
    ...mapGetters({
      userIsTrial: "plan/userIsTrial",
      currentPlan: "plan/currentPlan",
      planPricings: "plan/planPricings",
      daysLeftTrial: "plan/daysLeftTrial",
      siteId: "account/getSiteId",
      users: "user/users",
    }),
    userIsDowngrading() {
      const currentPlanIndex = this.subscriptionArray.findIndex(
        (subscription) => subscription.name === this.currentPlan
      );

      const chosenPlanIndex = this.subscriptionArray.findIndex(
        (subscription) => subscription.name === this.planChosen.name
      );
      return chosenPlanIndex < currentPlanIndex;
    },
    offendingDowngradeCriteria() {
      if (!planCriteria[this.planChosen]) return "";
      const criterias = planCriteria[this.planChosen];
      for (const criterion of criterias) {
        if (this[criterion.getter]) {
          const value = this[criterion.getter];
          if (
            (Array.isArray(value) && value.length > criterion.limit) ||
            (!Number.isNaN(value) && value > criterion.limit)
          ) {
            return this.$t(
              "billing.accountPlan.downgradeError." + criterion.name
            );
          }
        }
      }
      return "";
    },
  },
  async created() {
    await this.$store.dispatch("dashboard/dashboardGlobalMetricsforLast7Days", {
      siteId: this.$store.state.account.user.siteId,
      fromDate: this.getLast7DaysDate(),
      toDate: endOfToday(),
    });
    if (this.isAppsumo) {
      this.subscriptionArray = ["vidjet_tier1", "vidjet_tier2", "vidjet_tier3"];
    } else {
      const hasCustomObject = this.plan.plan.pricings.some(
        (pricing) => pricing.name === "custom"
      );

      if (!hasCustomObject) {
        this.subscriptionArray = [
          ...this.plan.plan.pricings,
          { name: "enterprise" },
        ];
      } else {
        this.subscriptionArray = this.plan.plan.pricings;
      }
    }
  },
  methods: {
    planDisplayed(payload) {
      this.planChosen = this.subscriptionArray[payload];
    },
    closeUpgradePlan() {
      this.showUpgradePlan = false;
      this.hideButton = !this.hideButton;
    },
    getLast7DaysDate() {
      var todayDate = new Date(),
        fromDate = new Date();
      fromDate.setTime(todayDate.getTime() - 7 * 24 * 3600000);
      return fromDate;
    },
    async upgradeToFreemiun() {
      const data = {
        siteId: this.siteId,
        chosenPlan: this.planChosen,
      };
      await this.$store.dispatch("plan/updatePlan", { data });
      this.$notify({
        title: this.$t("shared.toastMessage.planUpdated"),
        type: "success",
      });
    },
    handleSlideClicked(clickedSlide) {
      this.planChosen = clickedSlide;
      if (this.isAppsumo) {
        this.$notify({
          text: this.$t("shared.toastMessage.contactUpgrade"),
          type: "success",
        });
        return;
      } else if (this.planChosen === this.currentPlan) {
        return;
      } else if (this.planChosen.name === "enterprise") {
        window.Intercom &&
          window.Intercom("trackEvent", "plan_enterprise_clicked");
      } else this.showUpgradePlan = true;
    },
  },
};
</script>

<style scoped lang="scss">
.tooltip-content {
  display: inline-flex !important;
}
.carousel-card {
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.12);
  border-radius: $card-border-radius;
  border: 1px solid $light-grey;
  width: 100%;
}
.card-header {
  position: relative;
  display: flex;
}

.conversion-block {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.card-subheader {
  @include font-small;
  color: $medium-grey;
  text-align: center;
  margin-top: 20px;
  p {
    margin-top: 10px;
  }
}
.icon-account {
  display: flex;
  align-content: center;
  margin: 16px 0 0 24px;
}
.days-left-trial {
  position: absolute;
  top: 0;
  right: 0;
  background: var(
    --Blob-2,
    linear-gradient(210deg, #37ecba 13.4%, #75d473 86.6%)
  );
  border-radius: 0 0 0 10px;
  flex: 0.75;
  width: 220px;
  height: 40px;
  color: black;
  display: flex;
  .text {
    margin: auto;
  }
}
.mail-to {
  text-decoration: none;
}
.card-title {
  padding-left: 8px;
  align-self: center;
  @include font-normal;
}
.carousel-wrapper {
  padding: 24px;
}
.button-container {
  width: inherit;
}
.current-plan {
  text-align: center;
  margin: 10px;
  font-weight: 500;
}
.link-bottom {
  margin: 10px auto 10px auto;
  @include font-smallest;
  color: $light-grey;
  svg {
    height: 12px;
    vertical-align: middle;
    height: 100%;
    margin-left: 4px;
  }
}
@include media(">tablet", "<=desktop") {
  .carousel-card {
  }
}
@include media(">phone", "<=tablet") {
  .carousel-card {
  }
}
@include media("<=phone") {
  .card-title {
    @include font-small;
  }
  .carousel-card {
    height: 350px;
    margin-right: 0;
  }
}
</style>
