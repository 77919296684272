<template>
  <div class="box-toggle-section">
    <p v-if="header" :class="{ 'disabled-text': isDisabled }">{{ header }}</p>
    <slot> </slot>
    <div
      class="box-toggle-buttons"
      :style="`grid-template-columns: repeat(${options.length}, 1fr)`"
    >
      <label
        v-for="option in options"
        :key="option.value"
        class="box-toggle-button"
        :class="[
          'box-toggle-button',
          { 'box-toggle-button-selected': selectedValue === option.value },
          { disabled: isDisabled },
        ]"
      >
        <input
          class="radio-button"
          type="radio"
          :value="option.value"
          :checked="selectedValue === option.value"
          @click="emitOption(option)"
        />
        {{ option.text }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    selected: {
      type: [String, Number, Boolean],
      default() {
        return "";
      },
    },

    inputFor: {
      type: String,
      default() {
        return "";
      },
    },
    header: {
      type: String,
      default() {
        return "";
      },
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedValue: this.selected,
    };
  },

  watch: {
    selected(newValue) {
      this.selectedValue = newValue;
    },
  },

  methods: {
    emitOption(option) {
      this.selectedValue = option.value;
      this.$emit("option-selected", {
        inputFor: this.inputFor,
        value: option.value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box-toggle-section {
  @include font-small;
  @include base-font;
  text-align: left;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  &:hover {
    background-color: $settings-hover;
  }
}
.box-toggle-buttons {
  display: grid;
  justify-content: center;
}
.box-toggle-button {
  align-items: center;
  background-color: transparent;
  @include base-font;
  @include font-smaller;
  display: flex;
  color: $medium-grey;
  border: 1px solid $medium-grey;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 6px 0px;
  height: 24px;
  &:hover {
    cursor: pointer;
  }
}
.box-toggle-button:first-child {
  border-right: none;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.box-toggle-button:last-child {
  border-left: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.box-toggle-button-selected {
  background-color: $dark-purple;

  color: white;
  border: 1px solid $dark-purple;
}
.radio-button {
  display: none;
}
.disabled {
  pointer-events: none;
  border: 1px solid $light-grey;

  &.box-toggle-button-selected {
    background-color: $light-grey;

    color: white;
    border: 1px solid $light-grey;
  }
}
.disabled-text {
  color: $light-grey;
}
</style>
