var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:[
      'subscription-card',
      { 
        'current-plan': _vm.subscription.name === _vm.currentPlan,
      },
    ],on:{"click":_vm.handleCardClick}},[_c('h4',{staticClass:"title",class:{ 'current-plan-title': _vm.subscription.name === _vm.currentPlan }},[_vm._v(" "+_vm._s(_vm.$t( `billing.accountPlan.subscription.${ _vm.isAppsumo ? _vm.subscription : _vm.subscription.name }.title` ))+" ")]),_c('p',{staticClass:"price"},[(_vm.isAppsumo)?_c('span',[_vm._v(_vm._s(_vm.$t(`billing.accountPlan.subscription.${_vm.subscription}.price`)))]):(_vm.isCustomPlan)?_c('span',[_vm._v(_vm._s(_vm.$t(`billing.accountPlan.subscription.enterprise.price`)))]):_c('span',[_vm._v("$"+_vm._s(_vm.subscription.cost)),_c('span',{staticClass:"price-detail"},[_vm._v(_vm._s(_vm.$t("account.usage.month")))])])]),_c('div',{staticClass:"features"},[_c('ul',[(!_vm.isAppsumo)?_c('li',[_vm._v(" ✔ "+_vm._s(_vm.subscription.videosInCampaignsLimit || "Unlimited")+" "+_vm._s(_vm.$t(`billing.accountPlan.globalSubscriptionFeatures.videos`))+" ")]):_vm._e(),(!_vm.isAppsumo)?_c('li',[_vm._v(" ✔ "+_vm._s(_vm.subscription.videoViewLimit || "Unlimited")+" "+_vm._s(_vm.$t(`billing.accountPlan.globalSubscriptionFeatures.views`))+" ")]):_vm._e(),_vm._l((_vm.$t(
            `billing.accountPlan.subscription.${
              _vm.isAppsumo ? _vm.subscription : _vm.subscription.name
            }.features`
          )),function(feature){return _c('li',{key:feature.id},[_vm._v(" "+_vm._s(feature)+" ")])})],2),_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }