var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar"},[_c('ul',{staticClass:"list-buttons"},[(_vm.hideLogoIfAgencySite)?_c('router-link',{attrs:{"to":"/home"}},[_c('li',[_c('header',{staticClass:"navbar-header"},[_c(_vm.isHomeActive ? 'VidjetLogoDark' : 'VidjetLogoLight',{tag:"component",staticClass:"icon-logo",class:{ 'active-logo': _vm.isHomeActive }})],1)])]):_vm._e(),_vm._l((_vm.navigationTabs),function(tab){return _c('div',{key:tab.name,staticClass:"tabs-tippy"},[_c(tab.name === 'Agency' && !_vm.isAgency && !_vm.isAdmin
            ? 'span'
            : 'router-link',{tag:"router-link",staticClass:"navbar-list",attrs:{"to":tab.link}},[_c('tippy',{attrs:{"arrow":"true","placement":"right","a11y":"false","interactive":"true"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('li',{staticClass:"navbar-list-item",class:[
                'navbar-list-item',
                { active: _vm.isTabActive(tab) },
                { 'invisible-tab': tab.name === 'Admin' },
              ]},[_c(tab.iconComponent,{tag:"component"})],1)]},proxy:true}],null,true)},[_c('div',[_vm._v(" "+_vm._s(tab.tooltip)+" ")])])],1)],1)}),_c('div',{staticClass:"navbar-footer navbar-list"},[_vm._l((_vm.footerTabs),function(tab){return _c('div',{key:tab.name,staticClass:"tabs-tippy"},[_c(tab.name === 'Agency' && !_vm.isAgency && !_vm.isAdmin
              ? 'span'
              : 'router-link',{tag:"router-link",staticClass:"navbar-list",attrs:{"to":tab.link}},[_c('tippy',{attrs:{"arrow":"true","placement":"right","a11y":"false","interactive":"true"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('li',{staticClass:"navbar-list-item",class:[
                  'navbar-list-item',
                  { active: _vm.isTabActive(tab) },
                  { 'invisible-tab': tab.name === 'Admin' },
                ]},[_c(tab.iconComponent,{tag:"component"})],1)]},proxy:true}],null,true)},[(!_vm.isAgency && !_vm.isAdmin && tab.name === 'Agency')?_c('div',[_c('div',{staticClass:"tooltip-wrapper"},[_c('div',{staticClass:"tooltip-left"},[_c('div',{staticClass:"tooltip-title"},[_c('LockedIcon',{staticClass:"locked-icon"}),_c('h1',[_vm._v(_vm._s(_vm.$t("agencyPortal.tooltip.title")))])],1),_c('p',{staticClass:"tooltip-text"},[_vm._v(" "+_vm._s(_vm.$t("agencyPortal.tooltip.text"))+" ")])]),_c('button',{staticClass:"button-primary tooltip-button",on:{"click":_vm.openPlans}},[_vm._v(" "+_vm._s(_vm.$t("agencyPortal.tooltip.button"))+" ")])])]):_c('div',[_vm._v(" "+_vm._s(tab.tooltip)+" ")])])],1)],1)}),(_vm.showHelpIcon)?_c('li',{staticClass:"navbar-list-item"},[_c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            arrow: true,
            distance: 35,
            placement: 'right',
            a11y: false,
          }),expression:"{\n            arrow: true,\n            distance: 35,\n            placement: 'right',\n            a11y: false,\n          }"}],staticClass:"navbar-list-item",attrs:{"content":_vm.$t('shared.tooltip.help')},on:{"click":_vm.toggleReportIssueModal}},[_c('SupportIcon')],1)]):_vm._e()],2)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }