const state = {
  isBulk: false,
  isCodeFormat: false,
  isHomePage: false,
  showWidgetCreatedModal: false,
};

const mutations = {
  SET_IS_BULK(state, payload) {
    state.isBulk = payload;
  },
  SET_IS_CODE_FORMAT(state, payload) {
    state.isCodeFormat = payload;
  },
  SET_IS_HOME_PAGE(state, payload) {
    state.isHomePage = payload;
  },
  SET_SHOW_WIDGET_CREATED_MODAL(state, show) {
    state.showWidgetCreatedModal = show;
  },
};

const actions = {
  displayWidgetCreatedModal({ commit }, payload) {
    commit("SET_IS_BULK", payload.isBulk);
    commit("SET_IS_CODE_FORMAT", payload.isCodeFormat);
    commit("SET_IS_HOME_PAGE", payload.isHomePage);
    commit("SET_SHOW_WIDGET_CREATED_MODAL", payload.showWidgetCreatedModal);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};