<template>
  <header class="header">
    <div class="left-part">
      <h1>{{ title }}</h1>
    </div>
    <div class="right-part">
      <button
        v-if="showVideoLibraryButton"
        class="main-button"
        @click="openVideoLibrary"
      >
        <LibraryIcon />
        {{ $t("dashboard.heading.videoLibrary") }}
      </button>
      <button
        v-if="showCreateButton"
        class="main-button create-button"
        @click="addCampaign"
      >
        <PlusIcon class="plus-icon" />
        {{ $t("dashboard.heading.createButton") }}
      </button>
    </div>
    <!-- if proposed plan has a value then user needs to upgrade -->
    <PlanRegistration
      v-if="showPlanRegistration"
      :new-plan="proposedPlan"
      :show-arrows="currentPlan !== 'scale'"
      @close="showPlanRegistration = false"
    />
    <transition name="modal-slide">
      <VideoLibrarySlidingModal
        v-if="showVideoLibrary || videoLibraryCreation"
        :video-library-creation="videoLibraryCreation"
        @close-video-library="closeVideoLibrary"
      />
    </transition>
    <transition name="modal-slide">
      <WidgetCreatorSlidingModal
        v-if="isWidgetCreatorOpened"
        @close="closeWidgetCreator"
      />
    </transition>
    <WidgetCreatedModal
      v-if="showWidgetCreatedModal"
      :campaign="lastCampaign"
      :is-bulk="isBulk"
      :is-code-format="isCodeFormat"
      :is-home-page="isHomePage"
      @close="closeWidgetCreateModal"
    />
  </header>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import PlanRegistration from "@/components/upgrade-plan/PlanRegistration.vue";
import VideoLibrarySlidingModal from "@/components/shared/VideoLibrarySlidingModal.vue";
import LibraryIcon from "@/assets/svg/library-icon.svg?inline";
import PlusIcon from "@/assets/svg/small-plus-icon.svg?inline";
import WidgetCreatorSlidingModal from "@/components/create-campaign-steps/WidgetCreatorSlidingModal.vue";
import WidgetCreatedModal from "@/components/create-campaign-steps/WidgetCreatedModal.vue";
export default {
  name: "VidjetHeader",

  components: {
    PlanRegistration,
    VideoLibrarySlidingModal,
    LibraryIcon,
    PlusIcon,
    WidgetCreatorSlidingModal,
    WidgetCreatedModal,
  },
  props: {
    title: {
      type: String,
    },
    showCreateButton: {
      default: false,
      type: Boolean,
    },
    showVideoLibraryButton: {
      default: false,
      type: Boolean,
    },
    videoLibraryCreation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      proposedPlan: "",
      showPlanRegistration: false,
      showVideoLibrary: false,
      isWidgetCreatorOpened: false,
      campaigns: null,
    };
  },
  computed: {
    ...mapState({
      campaignsState: ({ campaign }) => campaign.campaigns,
      showWidgetCreatedModal: ({ widgetCreator }) =>
        widgetCreator.showWidgetCreatedModal,
      isBulk: ({ widgetCreator }) => widgetCreator.isBulk,
      isCodeFormat: ({ widgetCreator }) => widgetCreator.isCodeFormat,
      isHomePage: ({ widgetCreator }) => widgetCreator.isHomePage,
    }),
    ...mapState(["plan"]),
    ...mapGetters({
      currentPlan: "plan/currentPlan",
      numberOfVideosInCampaigns: "campaign/numberOfVideosInCampaigns",
      videosInCampaignsLimit: "plan/videosInCampaignsLimit",
      siteId: "site/getSiteId",
    }),
    lastCampaign() {
      return this.campaignsState.length > 0
        ? this.campaignsState[this.campaignsState.length - 1]
        : null;
    },
  },

  methods: {
    async addCampaign() {
      // this.$router.push({ name: "createCampaignStep1" });
      this.isWidgetCreatorOpened = true;
      this.$emit("open-widget-creator");
    },
    notifyTooManyVideos() {
      this.$notify({
        text: this.$t("shared.toastMessage.tooManyVideos"),
        type: "error",
      });
    },
    openVideoLibrary() {
      this.showVideoLibrary = true;
      this.$emit("show-video-library");
    },
    closeVideoLibrary() {
      this.showVideoLibrary = false;
      this.$emit("close-video-library");
    },
    closeWidgetCreator() {
      this.isWidgetCreatorOpened = false;
      this.$emit("close-widget-creator");
    },
    closeWidgetCreateModal() {
      this.$store.commit("widgetCreator/SET_SHOW_WIDGET_CREATED_MODAL", false);
      this.$store.commit("widgetCreator/SET_IS_BULK", false);
      this.$store.commit("widgetCreator/SET_IS_HOME_PAGE", false);
      this.$store.commit("widgetCreator/SET_IS_CODE_FORMAT", false);
    },
  },
};
</script>

<style lang="scss">
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  .left-part {
    display: flex;
    align-items: center;
  }

  .right-part {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .main-button {
    @include base-font;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 170px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid $dark-purple;
    background-color: transparent;
    color: $dark-purple;
    font-size: 14px;
    font-weight: 500;
    &:hover {
      background: $light-pink;
      color: $dark-purple;
    }
    &.create-button {
      background-color: $dark-purple;
      color: white;
      z-index: 1;
      &:hover {
        background: $light-pink;
        color: $dark-purple;
        .plus-icon {
          path {
            fill: $dark-purple;
          }
        }
      }
      @include media("<=tablet") {
        display: none;
      }
    }

    .create-campaign-text {
      padding: 10px;
      @include font-normal;
      @include base-font;
    }
  }
}

@include media(">phone", "<=tablet") {
  .header-create-campaign {
    top: 13%;
    left: 30%;
  }
}
@include media("<=phone") {
  .header-create-campaign {
    top: 13%;
    left: 30%;
  }
}
</style>
