var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"usage-wrapper"},[(_vm.billingDate || _vm.daysLeftTrial)?_c('BannerModal',{staticClass:"banner",attrs:{"title":_vm.bannerText,"title-font-weight":"400","content-gap":"0"}}):_vm._e(),_c('div',{staticClass:"bars-container"},[_c('div',{staticClass:"usage-bar"},[_c('p',{staticClass:"pageviews"},[_vm._v(_vm._s(_vm.$t("account.usage.videoAccount")))]),(_vm.videosInCampaignsLimit)?_c('section',[_c('div',{attrs:{"id":"campaignsBar-free"}},[_c('div',{style:({
              width:
                (_vm.numberOfVideosInLibrary / _vm.videosInCampaignsLimit) * 100 +
                '%',
            }),attrs:{"id":"campaignsBarFull"}}),_c('p',{staticClass:"indication"},[_vm._v(" "+_vm._s(_vm.numberOfVideosInLibrary)+" "+_vm._s(_vm.$t("account.usage.indications.hasSubscribed.outOf"))+" "+_vm._s(_vm.videosInCampaignsLimit)+" "+_vm._s(_vm.$t("account.usage.indications.hasSubscribed.included"))+" ")])])]):_c('section',[_c('div',{attrs:{"id":"campaignsBar-free"}},[_c('div',{staticStyle:{"width":"100%"},attrs:{"id":"campaignsBarFull"}}),_c('p',{staticClass:"indication"},[_vm._v(" "+_vm._s(_vm.$t("account.usage.indications.custom"))+" ")])])])]),_c('div',{staticClass:"usage-bar"},[_c('p',{staticClass:"pageviews"},[_vm._v(_vm._s(_vm.$t("account.usage.videoViewed")))]),(!_vm.videoViewsLimit)?_c('section',[_c('div',{attrs:{"id":"campaignsBar-free"}},[_c('div',{staticStyle:{"width":"100%"},attrs:{"id":"campaignsBarFull"}}),_c('p',{staticClass:"indication"},[_vm._v(" "+_vm._s(_vm.$t("account.usage.indications.custom"))+" ")])])]):_c('section',[_c('div',{attrs:{"id":"campaignsBar"}},[_c('div',{style:({
              width: (_vm.usage.usage.videoViews / _vm.videoViewsLimit) * 100 + '%',
            }),attrs:{"id":"campaignsBarFull"}}),_c('p',{staticClass:"indication"},[_vm._v(" "+_vm._s(_vm.userVideoViews)+" "+_vm._s(_vm.$t("account.usage.indications.hasSubscribed.outOf"))+" "+_vm._s(_vm.videoViewsLimit)+" "+_vm._s(_vm.$t("account.usage.indications.hasSubscribed.included"))+" ")])])])])]),_c('div',{staticClass:"plans-container"},[_c('p',{staticClass:"pageviews"},[_vm._v(_vm._s(_vm.$t("account.usage.monthlyPlan")))]),_c('div',{staticClass:"plan-cards"},_vm._l((_vm.plans),function(plan,index){return _c('div',{key:index,staticClass:"plan",class:{ 'selected-plan': _vm.currentPlan === plan.name },on:{"click":_vm.displayToastMessage}},[_c('span',{staticClass:"plan-info black"},[_vm._v(_vm._s(plan.name.toUpperCase()))]),_c('span',{staticClass:"plan-info"},[_vm._v(_vm._s(plan.videosInCampaignsLimit ? plan.videosInCampaignsLimit : "X")+" "+_vm._s(_vm.$t("account.usage.videos")))]),_c('span',{staticClass:"plan-info"},[_vm._v(_vm._s(plan.videoViewLimit ? plan.videoViewLimit : "X")+" "+_vm._s(_vm.$t("account.usage.plays")))]),_c('span',{staticClass:"plan-info black"},[_vm._v(_vm._s(plan.cost ? "$" + plan.cost + _vm.$t("account.usage.month") : "Custom"))])])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }