var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-background",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.close.apply(null, arguments)}}},[_c('div',{staticClass:"sliding-modal",class:[
      'sliding-modal',
      {
        'slide-right': _vm.slideDirection === 'right',
      },
      { 'widget-creator': _vm.isWidgetCreator },
      { 'widget-creator-third-step': _vm.isWidgetCreatorThirdStep },
    ]},[(_vm.isProductPages || _vm.isBulk)?_c('div',{staticClass:"product-pages-sliding-modal"}):_vm._e(),(_vm.campaign?.videos.length === 0 && (_vm.isProductPages || _vm.isBulk))?_c('div',{staticClass:"campaign-without-videos"}):_vm._e(),(!_vm.isWidgetCreator)?_c('button',{staticClass:"close-icon",on:{"click":_vm.close}},[_c('CloseIcon',{class:{ 'white-icon': _vm.campaign?.videos.length === 0 }})],1):_vm._e(),(_vm.feedName)?_c('h2',{staticClass:"feed-name"},[_vm._v(_vm._s(_vm.feedName))]):_vm._e(),(_vm.title)?_c('h1',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_vm._t("default"),_c('div',{staticClass:"cover"})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }