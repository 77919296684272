<template>
  <div>
    <vidjet-header
      class="vidjet-header"
      :title="$t('shared.tooltip.products')"
      :show-create-button="true"
      :show-video-library-button="true"
      :video-library-creation="videoLibraryCreation"
      @show-video-library="showVideoLibrary = true"
      @open-widget-creator="showWidgetCreator = true"
      @close-video-library="showVideoLibrary = false"
      @close-widget-creator="showWidgetCreator = false"
    />
    <ProductPages
      :show-widget-creator="showWidgetCreator"
      :show-video-library="showVideoLibrary"
    />
  </div>
</template>

<script>
import ProductPages from "@/components/ProductPages.vue";
import VidjetHeader from "./shared/Vidjet-header";
export default {
  components: {
    VidjetHeader,
    ProductPages,
  },
  data() {
    return {
      videoLibraryCreation: false,
      showVideoLibrary: false,
      showWidgetCreator: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.vidjet-header {
  margin: 37px 30px 24px 100px;
}
</style>
