<template>
  <fullscreen-modal :show-close-button="true">
    <section
      v-lazy:background-image="require('@/assets/step5-shapes.svg')"
      class="launchStep"
    >
      <div class="frames">
        <h1 class="launchStep-title">
          {{ $t("create.step5.heading") }}<br />
          <span class="bold">
            {{ $t("create.step5.subHeading") }}
          </span>
        </h1>

        <div class="campaign-name-frame">
          <h3 class="campaign-name-title">
            {{ $t("create.step5.name") }}
          </h3>
          <input
            v-model="campaignName"
            class="campaign-name-input"
            type="text"
            @change="saveCampaignName(campaign._id)"
          />
        </div>
        <div class="schedule-frame">
          <h3 class="schedule-title">
            {{ $t("create.step5.schedule.heading") }}
          </h3>
          <div class="flex">
            <vidjet-toggle-btn
              :checked="scheduleCampaign ? true : false"
              @change="scheduleCampaign = !scheduleCampaign"
            />
          </div>
          <div v-if="scheduleCampaign">
            <div class="schedule-dates">
              <div class="schedule-section">
                <label for="startDate">{{
                  $t("create.step5.schedule.startDate")
                }}</label>
                <!-- TODO: Make component -->
                <div class="scheduler-inputs">
                  <div class="scheduler-input-wrapper">
                    <SchedulerCalendarIcon />
                    <v-date-picker
                      v-model="startDate"
                      mode="date"
                      :min-date="minDate"
                      :locale="$i18n.locale"
                      color="purple"
                      :is-required="true"
                    >
                      <template #default="{ inputValue, inputEvents }">
                        <input
                          id="startDate"
                          class="datetime-input"
                          :value="inputValue"
                          readonly
                          v-on="inputEvents"
                        />
                      </template>
                    </v-date-picker>
                  </div>
                  <div class="scheduler-input-wrapper">
                    <SchedulerClockIcon />
                    <v-date-picker
                      v-model="startDate"
                      mode="time"
                      :min-date="minDate"
                      color="purple"
                      :minute-increment="30"
                      :is-required="true"
                      is24hr
                    >
                      <template #default="{ inputValue, inputEvents }">
                        <input
                          id="startDate"
                          class="datetime-input"
                          :value="inputValue"
                          readonly
                          v-on="inputEvents"
                        />
                      </template>
                    </v-date-picker>
                  </div>
                </div>
              </div>
              <div v-if="setEndDate" class="schedule-section">
                <label for="endDate">{{
                  $t("create.step5.schedule.endDate")
                }}</label>
                <div class="scheduler-inputs">
                  <div class="scheduler-input-wrapper">
                    <SchedulerCalendarIcon />
                    <v-date-picker
                      v-model="endDate"
                      mode="date"
                      :min-date="minDate"
                      :locale="$i18n.locale"
                      color="purple"
                      :is-required="true"
                    >
                      <template #default="{ inputValue, inputEvents }">
                        <input
                          id="endDate"
                          class="datetime-input"
                          :value="inputValue"
                          readonly
                          v-on="inputEvents"
                        />
                      </template>
                    </v-date-picker>
                  </div>
                  <div class="scheduler-input-wrapper">
                    <SchedulerClockIcon />
                    <v-date-picker
                      v-model="endDate"
                      mode="time"
                      color="purple"
                      :minute-increment="30"
                      :min-date="minDate"
                      :is-required="true"
                      is24hr
                    >
                      <template #default="{ inputValue, inputEvents }">
                        <input
                          id="endTime"
                          class="datetime-input"
                          :value="inputValue"
                          readonly
                          v-on="inputEvents"
                        />
                      </template>
                    </v-date-picker>
                  </div>
                  <button class="button-trash" @click="toggleSetEnd">
                    <TrashIcon />
                  </button>
                </div>
              </div>
              <button v-else class="button-setend" @click="toggleSetEnd">
                {{ $t("create.step5.schedule.optionalEnd") }}
              </button>
            </div>
            <div class="dropdown">
              <p class="timezone-indication">
                {{ $t("create.step5.schedule.timezone") }}
              </p>
              <v-select
                id="timezone"
                v-model="chosenTimezone"
                class="vidjet-single-select timezone"
                placeholder="Select a timezone"
                :options="timezones.map((timezone) => timezone.name)"
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <button
          class="action-button launch-campaign-button"
          @click="launchCampaign"
        >
          <AirplaneIcon />
          {{ $t("create.step5.launch") }}
        </button>
      </div>
    </section>
    <IntegrationPopUp
      v-if="showIntegrationPopup"
      @closePopUp="toggleIntegrationPopUp"
    />
  </fullscreen-modal>
</template>

<script>
import { mapGetters } from "vuex";

import IntegrationPopUp from "@/components/shared/IntegrationPopUp.vue";
import FullscreenModal from "@/components/shared/Fullscreen-modal.vue";
import SchedulerCalendarIcon from "@/assets/svg/scheduler-calendar.svg?inline";
import VidjetToggleBtn from "@/components/shared/Vidjet-toggle-btn.vue";
import campaignCreationMixin from "./campaignCreationMixin";

import SchedulerClockIcon from "@/assets/svg/scheduler-clock.svg?inline";
import TrashIcon from "@/assets/svg/datepicker-trash.svg?inline";
import AirplaneIcon from "@/assets/svg/airplane-icon.svg?inline";
import enums from "@/enums";
import timezonesEn from "@/utils/timezones-en.js";
import timezonesFr from "@/utils/timezones-fr.js";
import { isAfter } from "date-fns";
const { StateFlag, ComparaisonType } = enums;
export default {
  name: "CreateCampaignStep5",
  components: {
    FullscreenModal,
    SchedulerCalendarIcon,
    SchedulerClockIcon,
    TrashIcon,
    IntegrationPopUp,
    VidjetToggleBtn,
    AirplaneIcon,
  },

  mixins: [campaignCreationMixin],

  props: {
    isEditMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      timezones: [],
      timezonesEn,
      timezonesFr,
      chosenTimezone: "",
      scheduleCampaign: false,
      campaignName: "",
      site: {},
      campaignId: "",
      campaign: { name: "" },
      setEndDate: false,
      startDate: new Date(),
      endDate: new Date(),
      showIntegrationPopup: false,
      timezone: "",
      // calledFrom: tracks if integration was triggered from preview or launch
      calledFrom: "",
      defaultUrl: true,
    };
  },

  computed: {
    ...mapGetters({
      getCampaignCreation: "campaign/campaignCreation",
      nextPlan: "plan/nextPlan",
      currentPlan: "plan/currentPlan",
    }),
    minDate() {
      const now = new Date();
      now.setHours(now.getHours() + 1);
      return now;
    },
    endSchedule() {
      const now = new Date();
      now.setHours(now.getHours() + 1);
      return now;
    },
    formattedStartDate() {
      return this.formatDateAndTimeIso(new Date(this.startDate));
    },
    formattedEndDate() {
      return this.formatDateAndTimeIso(new Date(this.endDate));
    },
    setStartDate() {
      return new Date(this.formattedStartDate) > new Date();
    },
    findChosenTimezoneOffset() {
      return this.timezones.find(
        (timezone) => timezone.name === this.chosenTimezone
      ).offset;
    },
    findTimezone() {
      return this.timezones.find(
        (timezone) => timezone.offset === new Date().getTimezoneOffset() / -60
      );
    },

    // filters array of segmentations urls to only keep contains and equal to
    checkSegmentation() {
      if (this.campaign.segmentation.urls) {
        const urlArray = this.campaign.segmentation.urls.filter(
          (url) =>
            url.comparaisonType === ComparaisonType.contains ||
            url.comparaisonType === ComparaisonType.equalTo
        );
        return urlArray;
      }
      return [];
    },
  },
  async created() {
    await this.$store.dispatch("campaign/getSingle", {
      campaignId: this.$route.params.campaignId,
      siteId: this.getSiteId,
    });
    this.campaign = this.$store.state.campaign.singleCampaign
      ? Object.assign({}, this.$store.state.campaign.singleCampaign)
      : null;

    if (!this.campaign || this.campaign.stateFlag !== StateFlag.paused) {
      return this.$router.push({ name: "campaignManager" });
    }

    await this.$store.dispatch("site/getSite", {
      siteId: this.campaign.siteId,
    });
    this.site = Object.assign({}, this.$store.state.site.site);
    this.campaignName = this.campaign.name ? this.campaign.name : "";
    if (this.campaign.setEndDate) {
      this.endDate = this.adjustTimeBack(this.campaign.setEndDate);
      if (new Date(this.endDate) < new Date()) {
        this.endDate = new Date();
      }
    }

    this.timezonesLangSwitcher();
    // display correct timezone in dropdown
    if (this.site.timezone) {
      this.chosenTimezone = this.timezones.find(
        (timezone) => timezone.offset === parseInt(this.site.timezone, 10)
      ).name;
    } else {
      // by default display user's navigator TZ
      this.chosenTimezone = this.findTimezone.name;
    }
    // open campaign scheduler if in edit mode the campaign is schedule
    if (
      Boolean(this.campaign.setStartDate) &&
      new Date(this.campaign.setStartDate) > new Date()
    ) {
      this.scheduleCampaign = true;
    }
    if (
      Boolean(this.campaign.setEndDate) &&
      new Date(this.campaign.setEndDate) > new Date()
    ) {
      this.setEndDate = true;
    }
  },
  beforeRouteEnter(t, f, next) {
    next(async (vm) => {
      vm.init();
    });
  },
  methods: {
    // on edit time is converted back to user prefered timezone
    adjustTimeBack(date) {
      if (this.site.timezone) {
        date = new Date(date);
        const numberOfHours = date.getHours();
        const offset = parseInt(this.site.timezone, 10);
        // to account for the time diff between the two timezones we need to aggregrate the offset from utc of the two timezones
        new Date(
          date.setHours(
            date.getHours() + (offset + new Date().getTimezoneOffset() / 60)
          )
        );
        // adjust day
        if (numberOfHours + offset > 23) {
          date = new Date(date);
          date.setDate(date.getDate() + 1);
        } else if (numberOfHours + offset < 0) {
          date = new Date(date);
          date.setDate(date.getDate() - 1);
        }
      }
      return date;
    },
    formatDateAndTimeIso(date) {
      // adjust time to timezone if there is a chosen timezone or if the campaign is scheduled
      if (Boolean(this.chosenTimezone) && this.scheduleCampaign) {
        const numberOfHours = date.getHours();
        let offset = null;
        offset = this.findChosenTimezoneOffset;
        // to account for the time diff between the two timezones we need to aggregrate the offset from utc of the two timezones

        new Date(
          date.setHours(
            date.getHours() - (offset - new Date().getTimezoneOffset() / -60)
          )
        );

        // adjust day
        if (numberOfHours - offset > 23) {
          date = new Date(date);
          date.setDate(date.getDate() + 1);
        } else if (numberOfHours - offset < 0) {
          date = new Date(date);
          date.setDate(date.getDate() - 1);
        }
        // if the the user has no prefered timezone or if it's a new one selected timezone it gets saved to db
        if (
          Boolean(!this.site.timezone) ||
          this.site.timezone != String(this.findChosenTimezoneOffset)
        ) {
          this.updateTimezone();
        }
        return date.toISOString();
      }
    },

    timezonesLangSwitcher() {
      if (this.$i18n.locale === "en") {
        this.timezones = this.timezonesEn;
      } else {
        this.timezones = this.timezonesFr;
      }
    },

    toggleSetEnd() {
      this.setEndDate = !this.setEndDate;
      this.endDate = this.startDate;
      this.endTime = this.endSchedule;
    },

    async updateTimezone() {
      const timezoneUpdate = this.findChosenTimezoneOffset;
      await this.$store.dispatch("site/updateSite", {
        siteId: this.site._id,
        dataToUpdate: { timezone: String(timezoneUpdate) },
      });
    },

    previewUrl() {
      if (this.checkSegmentation.length > 0) {
        const regex =
          /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        const validUrl = this.checkSegmentation.find((url) =>
          regex.test(url.string)
        );
        if (validUrl) {
          this.defaultUrl = false;
          return `${validUrl.string}?vidjet=video-preview&campaignId=${this.campaign._id}`;
        }
      }
      return (
        this.site.websiteUrl &&
        `${this.site.websiteUrl}?vidjet=video-preview&campaignId=${this.campaign._id}`
      );
    },

    async close() {
      this.$router.push({ name: "campaignManager" });
    },
    async launchCampaign() {
      if (
        this.setEndDate &&
        new Date(this.formattedStartDate) > new Date(this.formattedEndDate)
      ) {
        this.$notify({
          title: "Error",
          text: this.$t("shared.toastMessage.dateLogic"),
          type: "error",
        });
        // can be removed because we no longer check here
      } else if (this.hasUserReachedActiveVideoLimit && this.site.integration) {
        this.toggleUpgradeModal();
      } else {
        if (!this.site.integration) {
          this.showIntegrationPopup = true;
          this.$notify({
            title: this.$t("shared.toastMessage.error"),
            text: this.$t("integration.toastMessage"),
            type: "error",
          });
          this.calledFrom = "launch";
        } else {
          const dataToUpdate = {
            name: this.campaignName,
          };

          // These are formatted (date/time to ISO)
          if (this.setStartDate) {
            dataToUpdate.setStartDate = this.formattedStartDate;
            dataToUpdate.stateFlag = StateFlag.pause;
          }
          if (
            this.setEndDate &&
            isAfter(this.formattedEndDate, this.formattedStartDate)
          ) {
            dataToUpdate.setEndDate = this.formattedEndDate;
          }

          if (this.setStartDate === false) {
            dataToUpdate.setStartDate = "";
            dataToUpdate.stateFlag = StateFlag.active;
          }
          // if scheduler closed set the campaign as active
          if (!this.scheduleCampaign) {
            dataToUpdate.setStartDate = "";
            dataToUpdate.setEndDate = "";
            dataToUpdate.stateFlag = StateFlag.active;
          }
          try {
            await this.$store.dispatch("campaign/updateCampaign", {
              campaignId: this.campaign._id,
              dataToUpdate,
              isLaunchCampaign: true,
            });
            this.$store.commit("video/resetVideos");
            this.$notify({
              title: this.$t("shared.toastMessage.success"),
              text: this.$t("shared.toastMessage.campaignLaunched"),
              type: "success",
            });
            this.$router.push({ name: "campaignManager" });
          } catch (err) {
            console.log(err);
            this.$notify({
              title: this.$t("shared.toastMessage.error"),
              text: err,
              type: "error",
            });
          }
        }
      }
    },

    integrationBeforePreview() {
      this.showIntegrationPopup = true;
      this.calledFrom = "preview";
    },

    // get latest store when closing modal to know which action to perform
    async toggleIntegrationPopUp() {
      await this.$store.dispatch("site/getSite", { siteId: this.site._id });
      this.site = Object.assign({}, this.$store.state.site.site);
      this.showIntegrationPopup = false;
      if (this.calledFrom === "launch" && Boolean(this.site.integration)) {
        this.launchCampaign();
      } else if (
        this.calledFrom === "preview" &&
        Boolean(this.site.integration)
      ) {
        window.open(
          this.site.websiteUrl &&
            `${this.site.websiteUrl}?vidjet=video-preview&campaignId=${this.campaign._id}`,
          "_blank"
        );
      }
    },
  },
};
</script>

<style lang="scss">
.timezone {
  .vs__dropdown-toggle,
  .vs__dropdown-menu {
    max-width: 190px;
  }
}
</style>

<style lang="scss" scoped>
.launchStep {
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  @include base-font;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  .button-trash {
    background: none;
  }

  .launch-campaign-button {
    max-width: 450px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    color: black;
  }

  .button-setend {
    @include base-font;
    @include font-smaller;
    font-weight: 500;
    background: none;
    text-decoration: underline;
    display: flex;
    padding-top: 22px;
  }

  .campaign-name-frame,
  .schedule-frame {
    @include frame;
    padding: 25px;
  }

  .launchStep-title {
    @include font-big;
    text-align: center;
    .bold {
      font-weight: 800;
    }
  }
  .frames {
    max-width: 450px;
    @include flex-col-gap(32px);
    justify-content: center;
    height: 100%;
    
    .campaign-name-frame {
      .campaign-name-title {
        @include font-normal;
        color: $dark-grey;
        margin: 10px auto;
      }
      .campaign-name-input {
        @include base-font;
        @include font-small;
        border: 1px solid $light-grey;
        border-radius: 3px;
        padding: 4px 8px;
        color: black;
        &:focus {
          outline: none;
          box-shadow: 0px 0px 0px 1px rgba(226, 124, 252, 0.7);
        }
      }
    }
    .schedule-frame {
      position: relative;
      .flex {
        position: absolute;
        top: 25px;
        right: 16px;
      }
      .schedule-title {
        @include font-normal;
        color: $dark-grey;
        margin-bottom: 5px;
      }
      .schedule-dates {
        margin-left: 10px;
        display: flex;
        .schedule-section {
          @include font-smallest;
          font-weight: 500;
          .scheduler-inputs {
            display: flex;
            margin-top: 5px;
            margin-right: 4px;
            .scheduler-input-wrapper {
              display: flex;
              padding: 5px 0;
              border: 1px solid black;
              align-items: center;
              svg {
                margin: 0 5px;
              }
              &:first-of-type {
                border-right: 1px solid transparent;
                border-radius: 3px 0px 0px 3px;
              }
              &:nth-of-type(2) {
                border-radius: 0px 3px 3px 0px;
              }
              .datetime-input {
                width: 70px;
                border: none;
                @include base-font;
                @include font-smallest;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }

  .current-timezone {
    @include font-small;
    margin-top: 8px;
    span {
      color: $light-grey;
      text-decoration: underline;
      @include font-smaller;
      cursor: pointer;
    }
  }

  .preview-website {
    background-color: #a6a4ef;
    border-radius: $card-border-radius;
    width: 222px;
    height: 66px;
    color: #ffffff;
    font-size: 17px;
    margin-top: 50px;
    border: none;
    outline: none;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.18);
    cursor: pointer;
  }

  .create-launch {
    background-color: #8ddb9a;
    border-radius: $card-border-radius;
    width: 222px;
    height: 66px;
    color: #ffffff;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.05em;
    font-family: Roboto;
    margin-top: 50px;
    border: none;
    outline: none;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.18);
    cursor: pointer;
  }

  .dropdown {
    margin-left: 10px;
    width: 95%;
  }
  .timezone-indication {
    margin-top: 12px;
    margin-bottom: 5px;
    @include font-smallest;
  }
}
</style>
