<template>
  <SlidingModal @close="closeEditWidget">
    <section v-if="editCampaignMenu" class="edit-campaign">
      <div class="campaign-summary">
        <div class="header-wrapper">
          <h1 class="edit-campaign-title">{{ $t("editCampaign.title") }}</h1>
          <div class="step-header">
            <p v-if="editName">
              <span>{{ $t("editCampaign.name") }}</span>
              <input
                id="campaign-name"
                v-model="campaignName"
                type="text"
                @change="updateCampaignName"
              />
            </p>
            <p v-else @click="editName = !editName">
              <span>{{ $t("editCampaign.name") }}</span> {{ campaignName }}
            </p>
            <div class="button-pen" @click="editName = !editName">
              <Pen />
            </div>
          </div>
        </div>
        <div class="settings-wrapper">
          <div class="edit-block">
            <div class="edit-block-title">
              <VideosIcon />
              <h3 class="campaign-title">
                {{ $t("editCampaign.videosAndElements") }}
              </h3>
            </div>
            <button class="pen-url" @click="makeChanges('design')">
              <div class="button-pen">
                <Pen />
              </div>
            </button>
            <div class="videos-text">
              <p class="description-bold">
                {{ $t("editCampaign.appearanceText.bold1") }}
              </p>
              <p class="description-text">
                {{ $t("editCampaign.appearanceText.normal1") }}
              </p>
              <p class="description-text">
                {{ $t("editCampaign.appearanceText.normal2") }}
              </p>
              <p class="description-text">
                {{ $t("editCampaign.appearanceText.normal3") }}
              </p>
              <p class="description-text">
                {{ $t("editCampaign.appearanceText.normal4") }}
              </p>
            </div>
          </div>

          <div v-if="!isEmbed && !isCarousel" class="settings-block">
            <div class="edit-block-title">
              <WrenchIcon />
              <h3 class="campaign-title">{{ $t("editCampaign.display") }}</h3>
            </div>
            <button class="pen-url" @click="makeChanges('conditions')">
              <div class="button-pen">
                <Pen />
              </div>
            </button>
            <div v-if="campaign.segmentation" class="condition-summary">
              <div
                v-if="
                  !campaign.segmentation.url && !campaign.segmentation.productId
                "
              >
                <p class="display">
                  <span>{{ $t("editCampaign.pages") }} </span
                  >{{ $t("editCampaign.anyPages") }}
                </p>
              </div>
              <div v-else>
                <p class="display">
                  <span>{{ $t("editCampaign.pages") }} </span
                  >{{ $t("editCampaign.specificPages") }}
                </p>
              </div>
              <div>
                <p class="display">
                  <span>{{ $t("editCampaign.trigger") }}</span
                  >{{ formatTrigger() }}
                </p>
              </div>

              <div>
                <p class="display">
                  <span>{{ $t("editCampaign.viewsRestriction") }} </span>
                  {{ $t("editCampaign.after") }}
                  {{ campaign.segmentation.viewRestriction.maxView }}
                  {{ $t("editCampaign.view") }}
                  {{ campaign.segmentation.viewRestriction.notShownTime }}
                  {{ $t("editCampaign.hours") }}
                </p>
              </div>
            </div>
          </div>
          <div v-else class="edit-block">
            <div class="edit-block-title">
              <WrenchIcon />
              <h3 class="campaign-title">Embed code</h3>
            </div>
            <button class="pen-url" @click="makeChanges('embed')">
              <div class="button-pen">
                <Pen />
              </div>
            </button>
            <div v-if="isCarousel">
              <p class="description-text">
                {{ $t("editCampaign.carouselText.Normal1") }}
              </p>
              <span class="description-bold">{{
                $t("editCampaign.carouselText.Bold1")
              }}</span>
              <p class="description-text">
                {{ $t("editCampaign.carouselText.Normal2") }}
              </p>
            </div>
            <div v-if="isEmbed">
              <p class="description-text">
                {{ $t("editCampaign.embedText.Normal1") }}
              </p>
              <span class="description-bold">
                {{ $t("editCampaign.embedText.Bold1") }}
              </span>
              <p class="description-text">
                {{ $t("editCampaign.embedText.Normal2") }}
              </p>
            </div>
          </div>
        </div>
        <div class="edit-summary-footer">
          <button
            class="button-primary btn-back button-no-shadows"
            @click="closeEditWidget"
          >
            {{ $t("shared.buttons.backToMenu") }}
          </button>
        </div>
      </div>
      <vidjet-preview
        v-if="campaign && campaign.videos && campaignLoaded"
        :is-embed="isEmbed"
        :is-edit-mode="true"
        :video-url="campaign.videos[0].url"
        :preview-campaign="campaign"
        :desktop="desktopFormat"
        :mobile="mobileFormat"
        :campaign-type="campaign.campaignType"
        :video-only="false"
        :all-videos="campaign.videos"
        :campaign-id="campaign._id"
        :has-campaign-state="!isCarousel"
        :chosen-device="chosenDeviceIsDesktop ? 'desktop' : 'mobile'"
        @change-display="chosenDeviceIsDesktop = !chosenDeviceIsDesktop"
      />
    </section>
    <Step3
      v-else-if="showStep3"
      :is-edit-mode="true"
      @edit-go-back="editGoBackFromStep3"
      @campaign-updated="updateCampaignFromStep3"
    />
    <Step4
      v-else-if="showStep4"
      :is-edit-mode="true"
      @edit-go-back="editGoBackFromStep4"
    />
    <spinner-loader v-if="isLoading"></spinner-loader>
  </SlidingModal>
</template>

<script>
import { mapGetters } from "vuex";
import VidjetPreview from "./shared/Vidjet-preview.vue";
import enums from "../enums";
import VideosIcon from "@/assets/svg/videos-icon.svg?inline";
import WrenchIcon from "@/assets/svg/wrench-icon.svg?inline";
import Pen from "./shared/Vidjet-pen-btn.vue";
import SpinnerLoader from "./shared/SpinnerLoader.vue";
const { FormatType, DeviceType, ComparaisonType, TriggerType, CtaType } = enums;
import SlidingModal from "@/components/shared/SlidingModal.vue";
import Step3 from "@/components/create-campaign-steps/Step3.vue";
import Step4 from "@/components/create-campaign-steps/Step4.vue";

export default {
  name: "EditCampaign",
  components: {
    VidjetPreview,
    Pen,
    VideosIcon,
    WrenchIcon,
    SpinnerLoader,
    SlidingModal,
    Step3,
    Step4,
  },
  beforeRouteEnter(t, f, next) {
    next(async (vm) => {
      vm.init();
    });
  },
  props: {
    selectedCampaign: {
      type: Object,
      default: null,
    },
    isCodeFormat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      campaign: {},
      desktopFormat: {},
      mobileFormat: {},
      editName: false,
      chosenDeviceIsDesktop: true,
      campaignName: "",
      campaignLoaded: false,
      isLoading: false,
      editCampaignMenu: true,
      showStep3: false,
      showStep4: false,
    };
  },
  computed: {
    ...mapGetters({
      getSiteId: "account/getSiteId",
      singleCampaign: "campaign/getSingle",
    }),
    displayDesktop() {
      return Boolean(this.desktopFormat.deviceType);
    },

    embedFormatObject() {
      if (!this.campaign || !this.campaign.formats) return false;
      return this.campaign.formats[0];
    },

    isEmbed() {
      return this.embedFormatObject.formatType === FormatType.embed;
    },

    isCarousel() {
      return this.embedFormatObject.formatType === FormatType.carousel;
    },

    mobileButtonType() {
      if (this.mobileFormat.cta) {
        return this.displayButtonType(this.mobileFormat.cta.ctaType);
      } else {
        return "N/A";
      }
    },

    desktopButtonType() {
      if (this.desktopFormat.cta) {
        return this.displayButtonType(this.desktopFormat.cta.ctaType);
      } else {
        return "N/A";
      }
    },
  },
  async created() {
    this.isLoading = true;
    this.$store.commit("video/RESET_UPLOAD_VIDEOS");
    await this.$store.dispatch(
      "campaign/setCampaignSelected",
      this.selectedCampaign._id
    );
    const fetchCampaign = await this.$store.dispatch("campaign/getSingle", {
      campaignId: this.selectedCampaign._id,
      siteId: this.getSiteId,
    });
    this.campaign = structuredClone(fetchCampaign.campaign);
    if (this.isCodeFormat) {
      this.editCampaignMenu = false;
      this.showStep3 = true;
    }

    // when a video is added get the thumbnail blob from the video object in the store
    if (this.isCarousel && this.videos && this.videos.length > 0) {
      const startingIndex = this.campaign.videos.length - this.videos.length;
      this.campaign.videos.forEach((video, index) => {
        if (index >= startingIndex) {
          video.thumbnail = this.videos[index - startingIndex].thumbnail;
        }
      });
    }

    if (this.campaign && this.campaign.formats) {
      if (this.campaign.formats.length === 2) {
        this.desktopFormat = { ...this.campaign.formats[0] };
        this.mobileFormat = { ...this.campaign.formats[1] };
      } else if (this.campaign.formats[0].deviceType === DeviceType.desktop) {
        this.desktopFormat = { ...this.campaign.formats[0] };
      } else {
        this.mobileFormat = { ...this.campaign.formats[0] };
        this.chosenDeviceIsDesktop = false;
      }
    }

    // here we need to add video details if needed
    if (!this.displayDesktop) this.chosenDevice = false;
    this.campaignName = this.campaign.name;
    this.campaignLoaded = true;
    this.isLoading = false;
  },
  methods: {
    async init() {
      if (!this.$route.params.campaignId) {
        return this.$router.push({ name: "campaignManager" });
      }
    },
    async updateCampaignName(e) {
      try {
        await this.$store.dispatch("campaign/updateCampaign", {
          campaignId: this.$route.params.campaignId,
          dataToUpdate: {
            name: e.currentTarget.value,
          },
        });
        this.$notify({
          title: this.$t("shared.toastMessage.success"),
          text: this.$t("shared.toastMessage.campaignUpdated"),
          type: "success",
        });
        this.editName = false;
      } catch (err) {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: err,
          type: "error",
        });
      }
    },
    retrieveFormatName(formatType, isStickyBar) {
      if (isStickyBar) return "Sticky";
      else if (formatType === FormatType.carousel) return "Carousel";
      else if (formatType === FormatType.popup) return "Popup";
      else if (formatType === FormatType.bubble) return "Bubble";
      else return "N/A";
    },
    formatUrlSegmentation(urlObj) {
      const comparaisonString =
        urlObj.comparaisonType === ComparaisonType.equalTo
          ? this.$t("editCampaign.equal")
          : urlObj.comparaisonType === ComparaisonType.notEqualTo
          ? this.$t("editCampaign.notEqual")
          : urlObj.comparaisonType === ComparaisonType.contains
          ? this.$t("editCampaign.contains")
          : this.$t("editCampaign.notContain");

      return `URL ${comparaisonString} ${urlObj.string}`;
    },
    formatTrigger() {
      const option = this.campaign.segmentation.trigger.option;
      const triggerFormated =
        this.campaign.segmentation.trigger.type === TriggerType.landing
          ? this.$t("editCampaign.landing")
          : this.campaign.segmentation.trigger.type === TriggerType.exit
          ? this.$t("editCampaign.exit")
          : this.campaign.segmentation.trigger.type === TriggerType.scroll
          ? this.$t("editCampaign.afterScrolling") +
            option +
            this.$t("editCampaign.ofThePage")
          : this.campaign.segmentation.trigger.type === TriggerType.delay
          ? this.$t("editCampaign.timeDelay") +
            option +
            this.$t("editCampaign.seconds")
          : "";

      return triggerFormated;
    },
    makeChanges(type) {
      switch (type) {
        case "design":
          // return this.$router.push({
          //   name: "editCampaignFormat",
          //   params: { campaignId: this.campaign._id },
          // });
          this.editCampaignMenu = false;
          this.showStep3 = true;
          return;
        case "conditions":
          this.editCampaignMenu = false;
          this.showStep4 = true;
          return;
        case "video":
          return this.$router.push({
            name: "editCampaignVideo",
            params: { campaignId: this.campaign._id },
          });
        case "embed":
          return this.$router.push({
            name: "editEmbedStep4",
            params: { campaignId: this.campaign._id },
          });
        default:
          return;
      }
    },

    displayButtonType(n) {
      if (n === CtaType.link) {
        return this.$t("editCampaign.urlLink");
      } else if (n === CtaType.copy) {
        return this.$t("editCampaign.code");
      } else if (n === CtaType.email) {
        return this.$t("editCampaign.emailCollector");
      } else if (n === CtaType.addToCart) {
        return this.$t("editCampaign.addToCart");
      } else {
        return "N/A";
      }
    },
    getFormatName(format) {
      if (this.embedFormatObject.isStory) return "Stories";
      if (this.isCarousel) return "Carousel";
      return this.retrieveFormatName(format.formatType, format.isStickyBar);
    },
    async closeEditWidget() {
      await this.$store.dispatch("campaign/resetCampaignSelected");
      this.$emit("close-edit-widget");
    },
    editGoBackFromStep3() {
      if (this.isCodeFormat) {
        this.closeEditWidget();
      } else {
        this.showStep3 = false;
        this.editCampaignMenu = true;
      }
    },
    editGoBackFromStep4() {
      this.showStep4 = false;
      this.editCampaignMenu = true;
    },
    updateCampaignFromStep3(updatedCampaign) {
      this.campaign = structuredClone(updatedCampaign);

      if (this.campaign.formats.length === 2) {
        this.desktopFormat = { ...this.campaign.formats[0] };
        this.mobileFormat = { ...this.campaign.formats[1] };
      } else if (this.campaign.formats[0].deviceType === DeviceType.desktop) {
        this.desktopFormat = { ...this.campaign.formats[0] };
      } else {
        this.mobileFormat = { ...this.campaign.formats[0] };
        this.chosenDeviceIsDesktop = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-wrapper {
  overflow-y: auto;
  padding: 8px;
}

.edit-campaign {
  @include base-font;
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  width: 100%;
  height: 100%;

  .hidden {
    display: none;
  }

  .header-wrapper {
    margin: 24px auto 0;
    line-height: 5px;
    text-align: center;
    .edit-campaign-title {
      margin-bottom: 10px;
      font-size: 24px;
    }
    span {
      @include font-small;
      font-weight: 700;
    }
    p {
      @include font-small;
      font-weight: 500;
      align-self: center;
      cursor: pointer;
    }
  }

  #campaign-name {
    @include input-style;
    @include font-normal;
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #c4c4c4;
  }

  .step-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .campaign-summary {
    width: 40%;
    min-width: 400px;
    max-width: 525px;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
  }

  .campaign-title {
    @include font-normal;
    text-align: center;
  }

  .pen-url {
    position: absolute;
    top: 10px;
    right: 12px;
    background: transparent;
  }

  .video-filename {
    text-align: start;
    width: 100%;
    word-break: break-all;
    @include font-smaller;
    font-weight: 500;
  }
  .description-text {
    width: 100%;
    @include font-smaller;
    font-weight: 400;
    display: inline;
  }
  .description-bold {
    @include font-smaller;
    font-weight: 600;
  }
  .edit-block {
    position: relative;
    @include card-box-shadow;
    width: 90%;
    margin: 32px auto;
    height: auto;
    padding: 16px 16px 24px;
    border-top: 4px solid $dark-purple;
  }

  .edit-block-title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin-bottom: 16px;
  }

  .design-summary-campaign {
    display: flex;
    flex-direction: row;
  }

  .design-format-summary {
    @include font-normal;
    margin-right: 20px;
    width: 45%;
  }

  .align {
    display: flex;
    width: 50%;
    margin: 0 auto;
    justify-content: space-evenly;
    @include font-small;
  }

  .format-and-text {
    @include font-small;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 80px;
    margin-left: 17px;
    span {
      font-weight: bold;
      color: $dark-purple;
    }
  }

  .design-format-summary-right {
    margin-left: 20px;
  }

  .separator {
    width: 1px;
    height: 90px;
    background-color: $light-grey;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 1);
  }

  .indication-iframe-modification {
    @include font-small;
  }

  .settings-block {
    position: relative;
    width: 90%;
    padding: 16px 16px 24px;
    margin: 0 auto;
    @include card-box-shadow;
    overflow-y: auto;
    margin-bottom: 12px;
    border-top: 4px solid $dark-purple;
  }

  .condition-summary {
    @include base-font;
    @include font-smaller;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    font-weight: 400;
    span {
      font-weight: 600;
      color: $dark-purple;
    }
  }

  .btn-back {
    margin: auto;
  }

  .video-url {
    text-align: center;
    font-weight: 300;
    font-size: 13px;
    margin-top: -8px;
  }

  .edit-summary-footer {
    margin: 16px 25px 30px 25px;
    display: flex;
  }

  .preview {
    background: rgba(226, 124, 252, 0.1);
    height: 100vh;
  }
  .ctas-edit {
    text-align: center;
  }
}
.videos-text {
  display: flex;
  flex-direction: column;
}
</style>
