<template>
  <div class="format-block" :class="{ 'bulk-format-block': isBulk }">
    <h3 class="format-block-title">
      {{ $t("create.step3.formatBlockTitle") }}
    </h3>
    <div v-if="!isEmbed" class="accordions-container">
      <vidjet-accordion v-if="formatObject.formatType === FormatType.bubble">
        <template #title>
          <span>{{ $t("create.step3.bubbleAppearance") }}</span>
        </template>
        <template #content>
          <bubble-appearance
            :format-object="formatObject"
            @change-format-object="handleFormatEvent"
          ></bubble-appearance>
        </template>
      </vidjet-accordion>
      <vidjet-accordion v-if="isCarousel || isStory">
        <template #title>
          <span>{{ $t("create.step3.thumbnail") }}</span>
        </template>
        <template #content>
          <text-with-btn-toggle
            input-for="animatedThumbnail"
            :preset-value="formatObject.animatedThumbnail"
            :label-text="'Animate thumbnails'"
            @toggle-button="handleFormatEvent"
          />
          <toggle-box
            v-if="isCarousel && !isCarouselInline && !isStory"
            :options="[
              { text: $t('shared.toggleBox.small'), value: 2 },
              { text: $t('shared.toggleBox.medium'), value: 1 },
            ]"
            :header="$t('shared.toggleBox.carouselThumbnailSize')"
            input-for="carouselThumbnailAmount"
            :selected="formatObject.carouselThumbnailAmount || 2"
            @option-selected="handleFormatEvent"
          >
            <tippy class="tippy" a11y="false">
              <template #trigger>
                <InterrogationMark
                  class="interrogation-mark thumbnails-tooltip"
                />
              </template>
              <div class="thumbnail-tooltip-container">
                <p>{{ $t("create.step3.thumbnailsMobile") }}</p>
                <img
                  src="@/assets/create-steps/image-for-tooltip.png"
                  alt="thumbnail size image"
                  class="thumbnail-size-image"
                />
              </div>
            </tippy>
          </toggle-box>
          <div
            v-if="isStory"
            class="color-setting"
            :class="{ 'bulk-color-setting': isBulk }"
          >
            <div class="color-setting-text">
              {{ $t("create.step3.buttonSelector.borderColor") }}
            </div>
            <button
              class="value-color"
              :style="`background-color:${formatObject.borderColor}`"
              @click="openColorPicker('borderColor')"
            />
          </div>
        </template>
      </vidjet-accordion>

      <vidjet-accordion :disabled="!currentPlan || currentPlan === 'free'">
        <template #tooltip>
          <div class="tooltip-content">
            <p>{{ $t("shared.tooltip.payingRestriction") }}</p>
            <router-link class="button-primary link" to="/settings/billing">
              {{ $t("shared.buttons.seePlans") }}
            </router-link>
          </div>
        </template>
        <template #title>
          <span>{{ $t("create.step3.playerIcons") }}</span>
        </template>
        <template #content>
          <div class="format-block-body">
            <TextWithBtnToggle
              v-if="!formatObject.isStory"
              :label-text="$t(`create.step3.hideThumbnailPlay`)"
              input-for="hideThumbnailPlay"
              :preset-value="formatObject.hideThumbnailPlay"
              @toggle-button="handleFormatEvent"
            />
            <TextWithBtnToggle
              v-if="formatObject.isStory"
              :label-text="$t(`create.step3.hideStoryPlay`)"
              input-for="hideStoryPlay"
              :preset-value="formatObject.hideStoryPlay"
              @toggle-button="handleFormatEvent"
            />
            <PlayIconSettings
              :is-bulk="isBulk"
              :campaign-format-options="formatObject"
              @change-format-object="handleFormatEvent"
            ></PlayIconSettings>
          </div>
        </template>
      </vidjet-accordion>
      <vidjet-accordion
        v-if="isCarousel && !isStory"
        :disabled="!currentPlan || currentPlan === 'free'"
      >
        <template #tooltip>
          <div class="tooltip-content">
            <p>{{ $t("shared.tooltip.payingRestriction") }}</p>
            <router-link class="button-primary link" to="/settings/billing">
              {{ $t("shared.buttons.seePlans") }}
            </router-link>
          </div>
        </template>
        <template #title>
          <span>{{ $t("create.step3.arrowIcons") }}</span>
        </template>
        <template #content>
          <div class="format-block-body">
            <ArrowSettings
              :is-bulk="isBulk"
              :campaign-format-options="formatObject"
              @change-format-object="handleFormatEvent"
            ></ArrowSettings>
          </div>
        </template>
      </vidjet-accordion>
      <vidjet-accordion>
        <template #title>
          <span>{{ $t("create.step3.playerSettings") }}</span>
        </template>
        <template #content>
          <TextWithBtnToggle
            v-if="!isFullScreen && !isCarousel"
            :disabled="formatObject.isFullScreen"
            :label-text="$t('create.step3.openByDefault')"
            input-for="openByDefault"
            :preset-value="formatObject.openByDefault"
            @toggle-button="handleFormatEvent"
          />
          <TextWithBtnToggle
            :label-text="$t(`create.step3.hideControlBar`)"
            input-for="hideControlBar"
            :preset-value="formatObject.hideControlBar"
            @toggle-button="handleFormatEvent"
          />
          <text-with-btn-toggle
            v-if="!isCarouselInline && isMultiple"
            :disabled="formatObject.autoLoop"
            :label-text="$t('create.step3.autoScroll')"
            input-for="autoScroll"
            :preset-value="formatObject.autoScroll"
            @toggle-button="handleFormatEvent"
          />
          <TextWithBtnToggle
            :disabled="formatObject.autoScroll"
            :label-text="$t('create.step3.autoLoop')"
            input-for="autoLoop"
            :preset-value="formatObject.autoLoop"
            @toggle-button="handleFormatEvent"
          />

          <!-- Only shw the moveY outside of the accordion for sticky bar -->
          <TextWithSlider
            v-if="isStickyBar"
            :label-text="$t('create.step3.bottomMargin')"
            input-for="moveY"
            :max-value="500"
            :step="5"
            :preset-value="formatObject.moveY"
            @toggle-button="handleFormatEvent"
          />
          <TextWithBtnToggle
            v-if="isMultiple && !isCarouselInline"
            :disabled="!isMultiple || formatObject.isHorizontalSwipe"
            :label-text="$t('create.step3.hideFeedArrow')"
            input-for="hideFeedArrow"
            :preset-value="formatObject.hideFeedArrow"
            @toggle-button="handleFormatEvent"
          />

          <toggle-box
            v-if="
              (isCarousel && !isCarouselInline) || (isBubble && isFullScreen)
            "
            :options="[
              { text: $t('shared.toggleBox.upDown'), value: false },
              { text: $t('shared.toggleBox.leftRight'), value: true },
            ]"
            :header="$t('shared.toggleBox.swipeDirection')"
            input-for="isHorizontalSwipe"
            :selected="formatObject.isHorizontalSwipe || false"
            @option-selected="handleFormatEvent"
          >
            <InterrogationMark
              v-tippy="{
                arrow: false,
                placement: 'right',
                a11y: false,
                boundary: 'window',
              }"
              class="interrogation-mark thumbnails-scrolling-tooltip"
              :content="$t('create.step3.scrollingDirection')"
            />
          </toggle-box>
          <toggle-box
            v-if="!(isBubble && isFullScreen) && !isStory"
            :header="$t('shared.toggleBox.corners')"
            input-for="cornerShape"
            :options="[
              { text: $t('shared.toggleBox.round'), value: 10 },
              { text: $t('shared.toggleBox.semi'), value: 5 },
              { text: $t('shared.toggleBox.square'), value: 0 },
            ]"
            :selected="selectedCornerShape"
            @option-selected="handleFormatEvent"
          ></toggle-box>
          <toggle-box
            v-if="isCarousel"
            :options="[
              { text: $t('shared.toggleBox.left'), value: 0 },
              { text: $t('shared.toggleBox.center'), value: 2 },
              { text: $t('shared.toggleBox.right'), value: 1 },
            ]"
            :header="$t('shared.toggleBox.alignment')"
            input-for="position"
            :selected="selectedPosition"
            @option-selected="handleFormatEvent"
          >
          </toggle-box>
        </template>
      </vidjet-accordion>
      <vidjet-accordion>
        <template #title>
          <span>{{ $t("create.step3.productCards") }}</span>
        </template>
        <template #content>
          <toggle-box
            v-if="isFullScreen"
            :options="[
              { text: $t('shared.toggleBox.noMargin'), value: 'no_margin' },
              { text: $t('shared.toggleBox.margin'), value: 'margin' },
            ]"
            :header="$t('shared.toggleBox.cardStyle')"
            input-for="productCardOptions.style"
            :selected="formatObject?.productCardOptions?.style || 'no_margin'"
            @option-selected="handleFormatEvent"
          />
          <toggle-box
            :options="[
              { text: $t('shared.toggleBox.noArrows'), value: 'no_arrows' },
              { text: $t('shared.toggleBox.arrows'), value: 'arrows' },
            ]"
            class="transition-toggle-box"
            :header="$t('shared.toggleBox.cardTransition')"
            input-for="productCardOptions.transition"
            :selected="
              formatObject?.productCardOptions?.transition || 'no_arrows'
            "
            @option-selected="handleFormatEvent"
          >
            <InterrogationMark
              v-tippy="{
                arrow: true,
                placement: 'top',
                a11y: false,
                boundary: 'window',
              }"
              content="If multiple products are tagged on a video"
              class="interrogation-mark transition-tooltip"
            />
          </toggle-box>
          <div class="styles-form">
            <label v-if="isFullScreen">{{
              $t("create.step3.buttonSelector.textStyleColor")
            }}</label>
            <div v-if="isFullScreen" class="style-inputs">
              <div class="text-styles">
                <button
                  class="button-input"
                  :class="{
                    selected: formatObject?.productCardOptions?.isBold,
                  }"
                  @click="toggleStyle(`isBold`)"
                >
                  <Bold></Bold>
                </button>
                <button
                  class="button-input"
                  :class="{
                    selected: formatObject?.productCardOptions?.isItalic,
                  }"
                  @click="toggleStyle(`isItalic`)"
                >
                  <Italic></Italic>
                </button>
                <button
                  class="button-input"
                  :class="{
                    selected: formatObject?.productCardOptions?.isUnderlined,
                  }"
                  @click="toggleStyle(`isUnderlined`)"
                >
                  <Underlined></Underlined>
                </button>
              </div>
              <div
                class="color-setting"
                :class="{ 'bulk-color-setting': isBulk }"
              >
                <button
                  class="value-color"
                  :style="`background-color:${productCardTextColor}`"
                  @click="openColorPicker('productCardOptions.color')"
                />
              </div>
            </div>
            <div
              class="color-setting"
              :class="{ 'bulk-color-setting': isBulk }"
            >
              <div class="color-setting-text">
                {{ $t("create.step3.buttonSelector.backgroundColor") }}
              </div>
              <button
                class="value-color"
                :style="`background-color:${productCardBackgroundColor}`"
                @click="openColorPicker('productCardOptions.backgroundColor')"
              />
            </div>
          </div>
          <TextWithBtnToggle
            v-if="isFullScreen"
            :label-text="$t(`create.step3.description`)"
            input-for="hasProductDescription"
            :preset-value="formatObject.hasProductDescription || false"
            @toggle-button="handleFormatEvent"
          />
        </template>
      </vidjet-accordion>
      <vidjet-accordion id="accordion-buttons">
        <template #title>
          <span> {{ $t("create.step3.buttonSelector.buttonTypes") }}</span>
        </template>
        <template #content>
          <div class="atc-type-container">
            <div class="atc-type-container">
              <toggle-box
                :options="[
                  {
                    text: $t('shared.toggleBox.addToCart'),
                    value: ctaAddToCart,
                  },
                  {
                    text: $t('shared.toggleBox.buyNow'),
                    value: ctaBuyNow,
                  },
                ]"
                input-for="cta.ctaAction"
                :header="$t('shared.toggleBox.mainButtonType')"
                :selected="formatObject?.cta?.ctaAction || ctaBuyNow"
                @option-selected="handleCtaChange"
              ></toggle-box>
            </div>
          </div>
          <h3 class="quick-shop-subtitle">
            {{ $t("create.step3.colorButtons") }}
          </h3>
          <div class="color-settings">
            <div class="quick-shop-color-setting">
              <button
                class="value-color"
                :style="`background-color:${ctaTextColor}`"
                @click="openColorPicker(`cta.textColor`)"
              />
              <div class="cta-title-format">
                {{ $t("create.step3.buttonSelector.textColor") }}
              </div>
            </div>
            <div class="quick-shop-color-setting">
              <button
                class="value-color"
                :style="`background-color:${ctaButtonColor}`"
                @click="openColorPicker(`cta.buttonColor`)"
              />
              <div class="cta-title-format">
                {{ $t("create.step3.buttonSelector.buttonColor") }}
              </div>
            </div>
          </div>
        </template>
      </vidjet-accordion>
      <vidjet-accordion v-if="isCarousel && !isStory">
        <template #title>
          <span>{{ $t("create.step3.quickShop") }}</span>
        </template>
        <template #content>
          <text-with-btn-toggle
            input-for="quickShop.isActive"
            :preset-value="formatObject?.quickShop?.isActive || false"
            :label-text="$t('create.step3.quickShopSubtitles.enable')"
            :has-tooltip="true"
            @toggle-button="handleQuickShopChange"
          />
          <p
            class="quick-shop-subtitle"
            :class="{ 'not-allowed': !formatObject.quickShop.isActive }"
          >
            {{ $t("create.step3.quickShopSubtitles.productColors") }}
          </p>
          <div class="color-settings">
            <div class="quick-shop-color-setting">
              <button
                class="value-color"
                :class="{ 'not-allowed': !formatObject.quickShop.isActive }"
                :style="`background-color:${quickShopProductTextColor}`"
                :disabled="!formatObject.quickShop.isActive"
                @click="openColorPicker('quickShop.productTextColor')"
              />
              <div
                class="cta-title-format"
                :class="{ 'not-allowed': !formatObject.quickShop.isActive }"
              >
                {{ $t("create.step3.buttonSelector.textColor") }}
              </div>
            </div>
            <div
              class="quick-shop-color-setting"
              :class="{ 'not-allowed': !formatObject.quickShop.isActive }"
            >
              <button
                class="value-color"
                :class="{ 'not-allowed': !formatObject.quickShop.isActive }"
                :style="`background-color:${quickShopProductBackgroundColor}`"
                :disabled="!formatObject.quickShop.isActive"
                @click="openColorPicker('quickShop.productBackgroundColor')"
              />
              <div class="cta-title-format">
                {{ $t("create.step3.buttonSelector.backgroundColor") }}
              </div>
            </div>
          </div>
          <toggle-box
            :options="[
              {
                text: $t('shared.toggleBox.addToCart'),
                value: ctaAddToCart,
              },
              {
                text: $t('shared.toggleBox.buyNow'),
                value: ctaBuyNow,
              },
            ]"
            input-for="quickShop.ctaAction"
            :header="$t('shared.toggleBox.ctaType')"
            :is-disabled="!formatObject.quickShop.isActive"
            :selected="formatObject?.quickShop?.ctaAction || ctaAddToCart"
            @option-selected="handleQuickShopChange"
          ></toggle-box>
          <p
            class="quick-shop-subtitle"
            :class="{ 'not-allowed': !formatObject.quickShop.isActive }"
          >
            {{ $t("create.step3.quickShopSubtitles.ctaColors") }}
          </p>
          <div class="color-settings">
            <div class="quick-shop-color-setting">
              <button
                class="value-color"
                :class="{ 'not-allowed': !formatObject.quickShop.isActive }"
                :style="`background-color:${quickShopButtonTextColor}`"
                :disabled="!formatObject.quickShop.isActive"
                @click="openColorPicker('quickShop.buttonTextColor')"
              />
              <div
                class="cta-title-format"
                :class="{ 'not-allowed': !formatObject.quickShop.isActive }"
              >
                {{ $t("create.step3.buttonSelector.textColor") }}
              </div>
            </div>
            <div class="quick-shop-color-setting">
              <button
                class="value-color"
                :class="{ 'not-allowed': !formatObject.quickShop.isActive }"
                :style="`background-color:${quickShopButtonBackgroundColor}`"
                :disabled="!formatObject.quickShop.isActive"
                @click="openColorPicker('quickShop.buttonBackgroundColor')"
              />
              <div
                class="cta-title-format"
                :class="{ 'not-allowed': !formatObject.quickShop.isActive }"
              >
                {{ $t("create.step3.buttonSelector.backgroundColor") }}
              </div>
            </div>
          </div>
        </template>
      </vidjet-accordion>
    </div>
    <div v-else class="format-block-body">
      <vidjet-accordion :disabled="!currentPlan || currentPlan === 'free'">
        <template #tooltip>
          <div class="tooltip-content">
            <p>{{ $t("shared.tooltip.payingRestriction") }}</p>
            <router-link class="button-primary link" to="/settings/billing">
              {{ $t("shared.buttons.seePlans") }}
            </router-link>
          </div>
        </template>
        <template #title>
          <span>{{ $t("create.step3.playerIcons") }}</span>
        </template>
        <template #content>
          <div class="format-block-body">
            <TextWithBtnToggle
              v-if="formatObject.isStory"
              :label-text="$t(`create.step3.hideStoryPlay`)"
              input-for="hideStoryPlay"
              :preset-value="formatObject.hideStoryPlay"
              @toggle-button="handleFormatEvent"
            />
            <TextWithBtnToggle
              :label-text="$t(`create.step3.hideThumbnailPlay`)"
              input-for="hideThumbnailPlay"
              :preset-value="formatObject.hideThumbnailPlay"
              @toggle-button="handleFormatEvent"
            />
            <PlayIconSettings
              :is-bulk="isBulk"
              :campaign-format-options="formatObject"
              @change-format-object="handleFormatEvent"
            ></PlayIconSettings>
          </div>
        </template>
      </vidjet-accordion>
      <vidjet-accordion>
        <template #title>
          <span>{{ $t("create.step3.playerSettings") }}</span>
        </template>
        <template #content>
          <text-with-btn-toggle
            v-if="isMultiple"
            :disabled="formatObject.autoLoop"
            :label-text="$t('create.step3.autoScroll')"
            input-for="autoScroll"
            :preset-value="formatObject.autoScroll"
            @toggle-button="handleFormatEvent"
          />
          <TextWithBtnToggle
            v-for="option in [
              'animatedThumbnail',
              'hideControlBar',
              'autoLoop',
            ]"
            :key="option.id"
            :label-text="$t(`create.step3.${option}`)"
            :input-for="option"
            :preset-value="formatObject[option]"
            @toggle-button="handleFormatEvent"
          />
          <TextWithBtnToggle
            :label-text="$t(`create.step3.responsive`)"
            input-for="responsive"
            :preset-value="formatObject.responsive"
            @toggle-button="handleFormatEvent"
          />
          <InterrogationMark
            v-tippy="{
              arrow: false,
              placement: 'right',
              a11y: false,
              boundary: 'window',
            }"
            content="When responsive is selected, our player
      will fill the container while preserving your video aspect ratio. Meaning
      you'll need to adjust the size of the parent container to modify the size
      of the player on your homepage"
            class="interrogation-mark tooltip-trigger-responsive"
          />

          <div
            v-if="
              Object.keys(formatObject).length > 0 && !formatObject.responsive
            "
            class="iframe-input-size"
          >
            <p>Iframe height</p>
            <input
              v-model.number="height"
              class="number-input"
              type="number"
              min="0"
              @change="validateInput('height')"
            />
            <p>px</p>
            <p>Iframe width</p>

            <input
              v-model.number="width"
              class="number-input"
              type="number"
              min="0"
              @change="validateInput('width')"
            />
            <p>px</p>
            <InterrogationMark
              v-tippy="{
                arrow: false,
                placement: 'right',
                a11y: false,
                boundary: 'window',
              }"
              content="When responsive is not selected, only width or height need to be specified. The second value will be calculated automatically based on your video dimensions to preserve aspect ratio."
              class="interrogation-mark tooltip-trigger-not-responsive"
            />
          </div>
          <toggle-box
            :header="$t('shared.toggleBox.corners')"
            input-for="cornerShape"
            :options="[
              { text: $t('shared.toggleBox.round'), value: 10 },
              { text: $t('shared.toggleBox.semi'), value: 5 },
              { text: $t('shared.toggleBox.square'), value: 0 },
            ]"
            :selected="selectedCornerShape"
            @option-selected="handleFormatEvent"
          ></toggle-box>
        </template>
      </vidjet-accordion>
      <vidjet-accordion>
        <template #title>
          <span>{{ $t("create.step3.productCards") }}</span>
        </template>
        <template #content>
          <toggle-box
            :options="[
              { text: $t('shared.toggleBox.noMargin'), value: 'no_margin' },
              { text: $t('shared.toggleBox.margin'), value: 'margin' },
            ]"
            :header="$t('shared.toggleBox.cardStyle')"
            input-for="productCardOptions.style"
            :selected="formatObject?.productCardOptions?.style || 'no_margin'"
            @option-selected="handleFormatEvent"
          >
            <InterrogationMark
              v-tippy="{
                arrow: true,
                placement: 'top',
                a11y: false,
                boundary: 'window',
              }"
              content="The product card resizes automatically if the player becomes too small"
              class="interrogation-mark transition-tooltip"
            />
          </toggle-box>
          <toggle-box
            :options="[
              { text: $t('shared.toggleBox.noArrows'), value: 'no_arrows' },
              { text: $t('shared.toggleBox.arrows'), value: 'arrows' },
            ]"
            class="transition-toggle-box"
            :header="$t('shared.toggleBox.cardTransition')"
            input-for="productCardOptions.transition"
            :selected="
              formatObject?.productCardOptions?.transition || 'no_arrows'
            "
            @option-selected="handleFormatEvent"
          >
            <InterrogationMark
              v-tippy="{
                arrow: true,
                placement: 'top',
                a11y: false,
                boundary: 'window',
              }"
              content="If multiple products are tagged on a video"
              class="interrogation-mark transition-tooltip"
            />
          </toggle-box>
          <div class="styles-form">
            <label>{{
              $t("create.step3.buttonSelector.textStyleColor")
            }}</label>
            <div class="style-inputs">
              <div class="text-styles">
                <button
                  class="button-input"
                  :class="{
                    selected: formatObject?.productCardOptions?.isBold,
                  }"
                  @click="toggleStyle(`isBold`)"
                >
                  <Bold></Bold>
                </button>
                <button
                  class="button-input"
                  :class="{
                    selected: formatObject?.productCardOptions?.isItalic,
                  }"
                  @click="toggleStyle(`isItalic`)"
                >
                  <Italic></Italic>
                </button>
                <button
                  class="button-input"
                  :class="{
                    selected: formatObject?.productCardOptions?.isUnderlined,
                  }"
                  @click="toggleStyle(`isUnderlined`)"
                >
                  <Underlined></Underlined>
                </button>
              </div>
              <div
                class="color-setting"
                :class="{ 'bulk-color-setting': isBulk }"
              >
                <button
                  class="value-color"
                  :style="`background-color:${productCardTextColor}`"
                  @click="openColorPicker('productCardOptions.color')"
                />
              </div>
            </div>
            <div
              class="color-setting"
              :class="{ 'bulk-color-setting': isBulk }"
            >
              <div class="color-setting-text">
                {{ $t("create.step3.buttonSelector.backgroundColor") }}
              </div>
              <button
                class="value-color"
                :style="`background-color:${productCardBackgroundColor}`"
                @click="openColorPicker('productCardOptions.backgroundColor')"
              />
            </div>
          </div>
        </template>
      </vidjet-accordion>
      <vidjet-accordion id="accordion-buttons">
        <template #title>
          <span> {{ $t("create.step3.buttonSelector.buttonTypes") }}</span>
        </template>
        <template #content>
          <div class="atc-type-container">
            <div class="atc-type-container">
              <toggle-box
                :options="[
                  {
                    text: $t('shared.toggleBox.addToCart'),
                    value: ctaAddToCart,
                  },
                  {
                    text: $t('shared.toggleBox.buyNow'),
                    value: ctaBuyNow,
                  },
                ]"
                input-for="cta.ctaAction"
                :header="$t('shared.toggleBox.mainButtonType')"
                :selected="formatObject?.cta?.ctaAction || ctaBuyNow"
                @option-selected="handleCtaChange"
              ></toggle-box>
            </div>
          </div>
          <h3 class="quick-shop-subtitle">
            {{ $t("create.step3.colorButtons") }}
          </h3>
          <div class="color-settings">
            <div class="quick-shop-color-setting">
              <button
                class="value-color"
                :style="`background-color:${ctaTextColor}`"
                @click="openColorPicker(`cta.textColor`)"
              />
              <div class="cta-title-format">
                {{ $t("create.step3.buttonSelector.textColor") }}
              </div>
            </div>
            <div class="quick-shop-color-setting">
              <button
                class="value-color"
                :style="`background-color:${ctaButtonColor}`"
                @click="openColorPicker(`cta.buttonColor`)"
              />
              <div class="cta-title-format">
                {{ $t("create.step3.buttonSelector.buttonColor") }}
              </div>
            </div>
          </div>
        </template>
      </vidjet-accordion>
    </div>
    <div
      v-if="displayPicker"
      key="color-picker"
      v-click-outside="hidePicker"
      class="vidjet-color-picker"
    >
      <colorpicker :value="currentColor" @input="onPickerChange" />
    </div>
  </div>
</template>

<script>
import VidjetAccordion from "../../shared/VidjetAccordion.vue";
import TextWithBtnToggle from "./TextWithBtnToggle.vue";
import TextWithSlider from "./TextWithSlider.vue";
import enums from "../../../enums";
import InterrogationMark from "@/assets/svg/settings/interrogation-mark.svg?inline";
import ToggleBox from "../../shared/ToggleBox.vue";
import BubbleAppearance from "./BubbleAppearance.vue";
import PlayIconSettings from "./PlayIconSettings.vue";
import ArrowSettings from "./ArrowSettings.vue";
import Bold from "@/assets/svg/text-options/text-bold.svg?inline";
import Italic from "@/assets/svg/text-options/text-italic.svg?inline";
import Underlined from "@/assets/svg/text-options/text-underlined.svg?inline";
import { Chrome } from "vue-color";

const { FormatType, CtaAction } = enums;
export default {
  name: "FormatBlock",

  components: {
    VidjetAccordion,
    TextWithSlider,
    TextWithBtnToggle,
    InterrogationMark,
    ToggleBox,
    BubbleAppearance,
    PlayIconSettings,
    ArrowSettings,
    colorpicker: Chrome,
    Bold,
    Italic,
    Underlined,
  },

  props: {
    chosenDevice: {
      type: String,
      default: "desktop",
    },
    formatObject: {
      type: Object,
      default: () => {
        return {};
      },
    },

    isEmbed: {
      type: Boolean,
      default: false,
    },

    isCarousel: {
      type: Boolean,
      default: false,
    },

    isMultiple: {
      type: Boolean,
      default: false,
    },

    videoSize: {
      type: Object,
      default: () => {
        return {};
      },
    },

    isCarouselInline: {
      type: Boolean,
      default: false,
    },

    isStory: {
      type: Boolean,
      default: false,
    },

    currentPlan: {
      type: String,
      default: "free",
    },
    isBulk: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      FormatType,
      formatRadioBtnArray: [
        {
          text: this.$t("create.step3.variants.bubble"),
          value: 1,
        },
        {
          text: this.$t("create.step3.variants.popup"),
          value: 0,
        },
      ],
      positionRadioBtnArray: [
        {
          text: this.$t("create.step3.left"),
          value: 0,
        },
        {
          text: this.$t("create.step3.right"),
          value: 1,
        },
      ],
      bubbleSizeRadioBtnArray: [
        {
          text: "S",
          value: 60,
        },
        {
          text: "M",
          value: 90,
        },
        {
          text: "L",
          value: 120,
        },
      ],
      thumbnailAmount: 2,
      width: 0,
      height: 0,
      displayPicker: false,
      currentProperty: null,
      currentColor: "",
      previousCornerShape: null,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
    };
  },

  computed: {
    isBubble() {
      return this.formatObject.formatType === FormatType.bubble;
    },

    isPopup() {
      return this.formatObject.formatType === FormatType.popup;
    },
    isStickyBar() {
      return this.formatObject.isStickyBar;
    },
    isFullScreen() {
      return this.formatObject.isFullScreen;
    },
    selectedCornerShape() {
      if (this.formatObject && this.formatObject.cornerShape !== undefined) {
        return this.formatObject.cornerShape || 0;
      } else {
        return 10;
      }
    },
    selectedPosition() {
      if (this.formatObject && this.formatObject.position !== undefined) {
        return this.formatObject.position || 0;
      } else {
        return 2;
      }
    },
    productCardTextColor() {
      return this.formatObject?.productCardOptions?.color || "#000000";
    },
    productCardBackgroundColor() {
      return (
        this.formatObject?.productCardOptions?.backgroundColor || "#FFFFFF"
      );
    },
    quickShopProductTextColor() {
      return this.formatObject?.quickShop?.productTextColor || "#FFFFFF";
    },
    quickShopProductBackgroundColor() {
      return this.formatObject?.quickShop?.productBackgroundColor || "#FFFFFF";
    },
    quickShopButtonTextColor() {
      return this.formatObject?.quickShop?.buttonTextColor || "#FFFFFF";
    },
    quickShopButtonBackgroundColor() {
      return this.formatObject?.quickShop?.buttonBackgroundColor || "#000000";
    },
    ctaTextColor() {
      return this.formatObject?.cta?.textColor || "#000000";
    },
    ctaButtonColor() {
      return this.formatObject?.cta?.buttonColor || "#FFFFFF";
    },
    ctaBuyNow() {
      return CtaAction.buyNow;
    },
    ctaAddToCart() {
      return CtaAction.addtoCart;
    },
  },
  watch: {
    "formatObject.cornerShape"(newValue, oldValue) {
      if (
        newValue !== oldValue &&
        (this.isCarousel || this.isStory || this.isEmbed)
      ) {
        this.$notify({
          title: this.$t("shared.toastMessage.success"),
          text: this.$t("create.step3.embedBorderRadiusNotify"),
          type: "success",
        });
      }
      this.previousCornerShape = oldValue;
    },
  },
  created() {
    this.previousCornerShape = this.formatObject.cornerShape;
  },

  methods: {
    handleFormatEvent(payload) {
      // force to show arrow when horizontal swipe is selected
      if (payload.inputFor === "isHorizontalSwipe" && payload.value === true) {
        this.$emit("change-format-object", {
          inputFor: "hideFeedArrow",
          value: false,
        });
      }
      const keys = payload.inputFor.split(".");

      if (keys.length > 1) {
        // Handle nested object properties
        let target = this.formatObject;

        for (let i = 0; i < keys.length - 1; i++) {
          const key = keys[i];
          if (!target[key]) this.$set(target, key, {});
          target = target[key];
        }
        this.$set(target, keys[keys.length - 1], payload.value);
      } else {
        this.$emit("change-format-object", payload);
      }
    },

    // make sure iframe size is always respecting aspect ratio of video
    validateInput(inputType) {
      if (inputType === "width") {
        const ratio = this.videoSize.width / this.width;
        this.height = this.videoSize.height / ratio;
      } else {
        const ratio = this.videoSize.height / this.height;
        this.width = this.videoSize.width / ratio;
      }
    },
    openColorPicker(property) {
      this.currentProperty = property;
      this.currentColor = this.getColorFromProperty(property);
      this.displayPicker = true;
    },

    getColorFromProperty(property) {
      const keys = property.split(".");
      let target = this.formatObject;
      keys.forEach((key) => {
        target = target[key];
      });
      if (property === "productCardOptions.color") {
        return target || "#000000";
      }
      if (property === "productCardOptions.backgroundColor") {
        return target || "#FFFFFF";
      }
      return target || "#000000";
    },

    onPickerChange(e) {
      if (!this.currentProperty.startsWith("cta")) {
        this.setColorToProperty(this.currentProperty, e.hex8);
      }
      if (this.currentProperty.startsWith("cta")) {
        const payload = {
          value: e.hex8,
          inputFor: this.currentProperty,
        };
        this.handleCtaChange(payload);
      }
      if (this.currentProperty.startsWith("quickShop")) {
        const payload = {
          value: e.hex8,
          inputFor: this.currentProperty,
        };
        this.handleQuickShopChange(payload);
      }
    },

    setColorToProperty(property, value) {
      const keys = property.split(".");
      let target = this.formatObject;
      for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];
        if (!target[key]) this.$set(target, key, {});
        target = target[key];
      }
      this.$set(target, keys[keys.length - 1], value);
    },

    hidePicker() {
      this.displayPicker = false;
      this.currentProperty = null;
    },
    toggleStyle(styleType) {
      if (styleType === "isBold") {
        this.isBold = !this.isBold;
      } else if (styleType === "isItalic") {
        this.isItalic = !this.isItalic;
      } else if (styleType === "isUnderlined") {
        this.isUnderlined = !this.isUnderlined;
      }

      if (!this.formatObject.productCardOptions) {
        this.$set(this.formatObject, "productCardOptions", {});
      }

      const currentValue = this.formatObject.productCardOptions[styleType];
      this.$set(this.formatObject.productCardOptions, styleType, !currentValue);
    },
    handleCtaChange(payload) {
      const propertiesMap = {
        "cta.ctaAction": "ctaAction",
        "cta.buttonColor": "buttonColor",
        "cta.textColor": "textColor",
      };

      const property = propertiesMap[payload.inputFor];
      if (property) {
        const updatedCta = {
          ...this.formatObject.cta,
          [property]: payload.value,
        };

        this.$emit("change-format-object", {
          inputFor: "cta",
          value: updatedCta,
        });
      }
    },
    handleQuickShopChange(payload) {
      const propertiesMap = {
        "quickShop.isActive": "isActive",
        "quickShop.productTextColor": "productTextColor",
        "quickShop.productBackgroundColor": "productBackgroundColor",
        "quickShop.buttonTextColor": "buttonTextColor",
        "quickShop.buttonBackgroundColor": "buttonBackgroundColor",
        "quickShop.ctaAction": "ctaAction",
      };

      const property = propertiesMap[payload.inputFor];
      if (property) {
        const updatedQuickShop = {
          ...this.formatObject.quickShop,
          [property]: payload.value,
        };

        this.$emit("change-format-object", {
          inputFor: "quickShop",
          value: updatedQuickShop,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.interrogation-mark {
  border: solid 1px #585858;
  border-radius: 50%;
  transform: scale(1.1);
  position: absolute;
}
// tooltip placement has to be rethought of
.tooltip-trigger-responsive {
  top: 16px;
  right: 16px;
}

.tooltip-trigger-not-responsive {
  right: 32px;
}

.thumbnails-scrolling-tooltip {
  top: 0;
  right: 0;
}

.thumbnail-tooltip-container {
  @include flex-col-gap(16px);
}

.thumbnail-size-image {
  height: 200px;
}

.iframe-input-size {
  display: grid;
  grid-template-columns: repeat(3, auto);
  place-items: flex-start;
  @include font-small;
  width: 90%;
  grid-gap: 16px 8px;
  justify-content: space-between;
}

.number-input {
  width: 60px;
}

.accordions-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.box-title {
  @include base-font;
  @include font-small;
  text-align: start;
}
.tooltip-content {
  display: flex;
  align-items: center;
  gap: 8px;
}
.bulk-format-block {
  max-width: 90%;
  margin-bottom: 120px;
}

.color-setting {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: $settings-hover;
  }
}

.color-setting-text {
  @include base-font;
  @include font-small;
}

.value-color {
  margin: 0;
}

.thumbnails-tooltip {
  position: absolute;
  top: 0;
  right: 0;
}

.styles-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
  label {
    text-align: start;
    @include base-font;
    @include font-small;
  }
}

.style-inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.button-input {
  border-radius: 4px;
  height: 32px;
  display: flex;
  width: 32px;
  align-items: center;
  justify-content: center;
  &.selected {
    background: $dark-purple;
    path {
      fill: white;
    }
  }
  &.wider {
    width: 42px !important;
  }
}

.text-styles {
  display: flex;
  gap: 8px;
}

.transition-toggle-box {
  position: relative;
}

.transition-tooltip {
  right: 0;
}
.quick-shop-color-setting {
  text-align: center;
}

.cta-title-format {
  margin-top: 5px;
  @include font-smallest;
  font-weight: 500;
}

.quick-shop-subtitle {
  align-self: flex-start;
  @include font-small;
  text-align: left;
}

.not-allowed {
  color: $light-grey;
  cursor: not-allowed;
}
</style>
