import axios from "axios";

export default {
  async getFeed(campaignId, feedIndex) {
    const requestOptions = {
      method: "get",
      url: `${process.env.VUE_APP_API_URL}/campaign/${campaignId}/bulk/get-feed`,
      headers: { "Content-Type": "application/json" },
      params: {
        feedIndex
      },
    };

    try {
      const res = await axios(requestOptions);
      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  },
  async createFeed(campaignId, data) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data,
    };

    try {
      const res = await axios(
        `${process.env.VUE_APP_API_URL}/campaign/${campaignId}/bulk/create-feed`,
        requestOptions
      );
      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  },
  async updateFeed(campaignId, data) {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data,
    };

    try {
      const res = await axios(
        `${process.env.VUE_APP_API_URL}/campaign/${campaignId}/bulk/update-feed`,
        requestOptions
      );
      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  },
  async deleteFeed(campaignId, feedIndex) {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      params: {
        feedIndex
      },
    };

    try {
      const res = await axios(
        `${process.env.VUE_APP_API_URL}/campaign/${campaignId}/bulk/delete-feed`,
        requestOptions,
      );

      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  },
  async addVideosToWidget(campaignId, data) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data,
    };

    try {
      const res = await axios(
        `${process.env.VUE_APP_API_URL}/campaign/${campaignId}/bulk/add-videos-to-widget`,
        requestOptions
      );
      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  }
};
