<template>
  <div>
    <div
      class="list-header"
      :class="{ 'centered-text': centeredText }"
      :style="numberOfColumns"
    >
      <p
        v-for="(header, index) in headerElements"
        :key="header.id"
        :class="{ 'no-wrap': isProductSegmentation && index === 0 }"
      >
        {{ header }}
      </p>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    headerElements: {
      type: Array,
      default() {
        return [];
      },
    },
    centeredText: {
      type: Boolean,
      default: false,
    },
    isProductSegmentation: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    numberOfColumns() {
      return `grid-template-columns: repeat(${this.headerElements.length}, 1fr);`;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-header {
  position: -webkit-sticky;
  position: sticky;
  display: grid;
  color: #585858;
  background: #ececec;
  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 2px;
  align-items: center;
  text-align: start;
  @include font-small;
  height: 100%;
  padding: 14px;
}
.centered-text {
  text-align: center;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.no-wrap {
  white-space: nowrap;
}
</style>
